// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { makeStyles } from '@material-ui/core/styles';

// --- External components
import PhoneInput from 'react-phone-input-2';
// import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

// --- Style
const useStyles = makeStyles(theme => ({
	root: {
		'& .form-control': {
			color: theme.palette.text.primary,
			'border-color': `${theme.palette.text.primary}40`,
		},
		'& .form-control:hover': {
			'border-color': theme.palette.text.primary,
		},
		'& .form-control:focus': {
			'border-color': theme.palette.primary.main,
		},
		'& .country-list': {
			background: theme.palette.background.paper,
		},
		'& .country-list .country.highlight': {
			background: theme.palette.background.backgroundPaper,
		},
		'& .country-list .country:hover': {
			background: theme.palette.background.backgroundPaper,
		},
	},
}));


const PhoneNumberInput = ({
	label,
	error,
	helperText,
	...props
}) => {
	const classes = useStyles();

	/*
	<InputLabel htmlFor={id} style={{ fontSize: '13px', marginBottom: '5px' }}>
		{label}
	</InputLabel>
	*/

	return (
		<div className={classes.root}>
			<PhoneInput
				{...props}
			/>
			<FormHelperText
				error={error}
			>
				{helperText}
			</FormHelperText>
		</div>
	);
};

PhoneNumberInput.propTypes = {
	// id: PropTypes.string,
	error: PropTypes.bool,
	// label: PropTypes.string,
	helperText: PropTypes.string,
	...PhoneInput.propTypes,
};

PhoneNumberInput.defaultProps = {
	error: false,
	helperText: null,
	// label: 'phone number',
	...PhoneInput.defaultProps,
};

export default PhoneNumberInput;
