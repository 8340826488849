// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const patchFilterPreference = (filterPreferenceID, changes, contextConfiguration) => axios.patch(
	`/zoekopdrachten/${filterPreferenceID}`,
	changes,
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default patchFilterPreference;
