// --- External tools
import axios from 'axios';


export function logError(error) {
	if (axios.isCancel(error)) {
		console.log('Request canceled:', error.message);
		return;
	}

	if (error.response) {
		console.error(
			'The request was made but the server responded with a status code that falls out of 2xx:\n',
			error.message
		);

		console.error(error.response.status, error.response);
		// console.error('Status', error.response.status);
		// console.error('Headers', error.response.headers);
		// console.error('Data', error.response.data);

		return;
	}

	if (error.request) {
		console.error('The request was made but no response was received:\n', error.message);
		console.error('Request:', error.request);
		return;
	}

	console.error('Something happened locally in setting up the request that triggered an Error:\n', error.message);
	console.error('Axios request config:', error.config);
}

export function onRequestError(error) {
	// Skips logging to the console in production.
	// if (process.env.NODE_ENV === 'development')
	logError(error);

	// CHECK Should return an empty object?
	// Silence the error (caught exception) if the request was cancelled locally.
	if (axios.isCancel(error))
		return;

	if (error.response) {
		// TODO Redirect that kind of error to a xxx error code page
		//  or modal to not lose the user input in the current page, depending on the severity?
		// throw error;
		return;
	}

	if (error.request) {
		// TODO Redirect that kind of error to an internal error page
		//  or modal to not lose the user input in the current page, depending on the severity?
		// throw error;
	}

	// TODO Same as above, internal error page.
	// throw error;
}
