// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';
import { isStringNullOrEmpty } from 'logic/stringOperations';

// --- External components
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import Chip from '@material-ui/core/Chip';
// import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

// --- Style
const styles = theme => ({});


class SortMenu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			anchorElement: null,
		};

		this.onClose = this.onClose.bind(this);
		this.onSelection = this.onSelection.bind(this);
	}

	render() {
		const {
			props: {
				t,
				classes,
				onClear,
				selectedSorting,
				supportedSorting,
			},
			state: {
				anchorElement,
			}
		} = this;

		if (supportedSorting == null)
			return <div />;

		const isSorting = !isStringNullOrEmpty(selectedSorting) && supportedSorting[selectedSorting].sort != null;

		const label = isSorting ? `${t(TranslationKey.button_sort_list_active)} ${t(TranslationKey[selectedSorting])}` : t(TranslationKey.button_sort_list);

		return (
			<>
				{/*
				<Button
					color="primary"
					variant="outlined"
					aria-haspopup="true"
					aria-controls="sort-menu"
					startIcon={<Icon>sort</Icon>}
					onClick={({ currentTarget }) => this.setState({ anchorElement: currentTarget })}
				>
					{
						isStringNullOrEmpty(selectedSorting) || supportedSorting[selectedSorting].sort == null
							? t(TranslationKey.button_sort_list)
							: `${t(TranslationKey.button_sort_list_active)}: ${t(TranslationKey[selectedSorting])}`
					}
				</Button>
				*/}
				<Chip
					clickable
					label={label}
					color="primary"
					variant="outlined"
					icon={<Icon>sort</Icon>}
					style={{ fontWeight: 700 }}
					onDelete={isSorting ? onClear : null}
					onClick={({ currentTarget }) => this.setState({ anchorElement: currentTarget })}
				/>
				<Menu
					keepMounted
					id="sort-menu"
					onClose={this.onClose}
					anchorEl={anchorElement}
					open={Boolean(anchorElement)}
				>
					{Object.keys(supportedSorting).map(id => (
						<MenuItem
							key={id}
							value={id}
							onClick={() => this.onSelection(id)}
							// style={{ textTransform: 'capitalize' }}
						>
							{t(TranslationKey[id])}
						</MenuItem>
					))}
				</Menu>
			</>
		);
	}


	// --- Event methods
	onSelection(id) {
		const {
			props: {
				onSelect,
				selectedSorting,
				supportedSorting,
			},
		} = this;

		if (selectedSorting === id) {
			this.onClose();
			return;
		}

		onSelect(id);
		this.onClose();
	}

	onClose() {
		this.setState({ anchorElement: null });
	}
}

SortMenu.propTypes = {
	onClear: PropTypes.func,
	t: PropTypes.func.isRequired,
	selectedSorting: PropTypes.string,
	onSelect: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	supportedSorting: PropTypes.objectOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			sort: PropTypes.func,
		})
	).isRequired,
};

SortMenu.defaultProps = {
	isOpen: false,
	selectedSorting: '',
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(SortMenu);
