// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const getFilterPreferenceByUserID = (userID, contextConfiguration) => axios.get(
	`/zoekopdrachten?user=${encodeURI(userID)}&fields=*`,
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default getFilterPreferenceByUserID;
