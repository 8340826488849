// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const getSupportedPropertyTypes = contextConfiguration => axios.get(
	'/soort_object',
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default getSupportedPropertyTypes;
