// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import {
	Route,
	Switch,
	Redirect,
	withRouter,
} from 'react-router-dom';

// --- IO
import API from 'io/API';

// --- Logic
import ThemeKey from 'logic/enums/ThemeKey';
import StorageKey from 'logic/enums/StorageKey';
import LoadingStatus from 'logic/enums/LoadingStatus';
import RangeOperator from 'logic/enums/RangeOperator';
import * as JsonExtension from 'logic/jsonOperations';
import AuthenticationStatus from 'logic/enums/AuthenticationStatus';
import { removeNullParametersFromObject } from 'logic/objectOperations';
import { isStringNullOrEmpty, getIdFromApiUrl } from 'logic/stringOperations';
import { formatUserData, getStoredSessionCredentials } from 'logic/userOperations';
import i18n, { defaultConfiguration as i18nDefaultConfiguration } from 'logic/translation/i18n';

// --- External components
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';

// --- Components
import AppTheme from 'visual/AppTheme';
import NavBar from 'visual/components/_/navigation/NavBar';
import SplashScreen from 'visual/components/_/loading/SplashScreen';
import ApplyFiltersModal from 'visual/components/modals/ApplyFiltersModal';
import { visitorRoutes, authenticatedRoutes } from 'visual/components/routes';

// --- Style
import './AppStyle.sass';
import ComparisonOperator from './logic/enums/ComparisonOperator';

const TransitionComponent = (props, ref) => <Slide direction="up" ref={ref} {...props} />;
export const Transition = React.forwardRef(TransitionComponent);


// First component (at the top of the hierarchy, aka. root component).
class App extends React.Component {
	constructor(props) {
		super(props);

		const { location } = props;

		let currentPath = '';
		if (location != null && !isStringNullOrEmpty(location.pathname)) {
			const path = location.pathname.substring(1).split('/', 1);

			if (path.length >= 1 && !isStringNullOrEmpty(path[0])) {
				// eslint-disable-next-line prefer-destructuring
				currentPath = path[0];
			}
		}

		this.state = {
			currentPath,
			userData: null,
			houseDetails: {},
			contactData: null,
			adCategories: null,
			currentPathname: '',
			houseList: undefined,
			filteredHouseList: [],
			supportedCities: null,
			supportedThemes: null,
			filterPreference: null,
			booleanSelectors: null,
			bookmarkDictionary: {},
			houseListFilters: null,
			informationTexts: null,
			isFilterModalOpen: false,
			supportedLanguages: null,
			supportedHouseStatus: null,
			houseListFiltersEnabled: true,
			supportedPropertyTypes: null,
			splashScreenAnimationEnded: false,
			isLoadingFilteredHouseList: false,
			appLoadingStatus: LoadingStatus.None,
			authenticationStatus: AuthenticationStatus.Unknown,
			theme: sessionStorage.getItem(StorageKey.Theme) || ThemeKey.Light,
		};

		this.onSignedIn = this.onSignedIn.bind(this);
		this.fetchHouses = this.fetchHouses.bind(this);
		this.onSignedOut = this.onSignedOut.bind(this);
		this.switchTheme = this.switchTheme.bind(this);
		this.fetchUserData = this.fetchUserData.bind(this);
		this.patchUserData = this.patchUserData.bind(this);
		this.toggleBookmark = this.toggleBookmark.bind(this);
		this.switchLanguage = this.switchLanguage.bind(this);
		this.getHouseDetails = this.getHouseDetails.bind(this);
		this.onAccountEdited = this.onAccountEdited.bind(this);
		this.onLocationChanged = this.onLocationChanged.bind(this);
		this.fetchFilteredHouses = this.fetchFilteredHouses.bind(this);
		this.onCredentialsChanged = this.onCredentialsChanged.bind(this);
		this.applyUserPreferences = this.applyUserPreferences.bind(this);
		this.fetchSupportedCities = this.fetchSupportedCities.bind(this);
		this.fetchInformationTexts = this.fetchInformationTexts.bind(this);
		this.clearHouseListFilters = this.clearHouseListFilters.bind(this);
		this.toggleHouseListFilters = this.toggleHouseListFilters.bind(this);
		this.updateHouseListFilters = this.updateHouseListFilters.bind(this);
		this.switchPushNotifications = this.switchPushNotifications.bind(this);
		this.getPreferencesForNewUser = this.getPreferencesForNewUser.bind(this);
		this.openHouseListFilterModal = this.openHouseListFilterModal.bind(this);
		this.switchEmailNotifications = this.switchEmailNotifications.bind(this);
		this.fetchUserFilterPreference = this.fetchUserFilterPreference.bind(this);
		this.fetchSupportedHouseStatus = this.fetchSupportedHouseStatus.bind(this);
		this.fetchSupportedPropertyTypes = this.fetchSupportedPropertyTypes.bind(this);
	}


	// --- Framework methods
	async componentDidMount() {
		this._ismounted = true;

		// Brings back the scrolling to the top of the page when the route changes.
		this.stopListeningToHistoryChanges = this.props.history.listen(this.onLocationChanged);

		const { appLoadingStatus } = this.state;

		if (appLoadingStatus !== LoadingStatus.None)
			return;

		await this.setState({ appLoadingStatus: LoadingStatus.Started });

		if (!this._ismounted)
			return;

		// Requests that must resolve before displaying the app.
		await Promise.all([
			this.fetchBooleanSelectors(),
			this.fetchLanguages(),
			this.fetchThemes(),
			this.fetchSupportedCities(),
			this.fetchSupportedHouseStatus(),
			this.fetchSupportedPropertyTypes(),
			this.fetchContactData(),
			this.fetchAdCategories(),
		]);

		if (!this._ismounted)
			return;

		// Requests that must resolve before displaying the app,
		// but after the initialization requests have resolved.
		await this.fetchUserData();

		if (!this._ismounted)
			return;

		if (this.state.authenticationStatus !== AuthenticationStatus.Authenticated) {
			await this.setState({ appLoadingStatus: LoadingStatus.Completed, splashScreenAnimationEnded: true });

			// Requests that can resolve after displaying the app.
			this.fetchInformationTexts();
			return;
		}

		await this.fetchHouses();

		if (!this._ismounted)
			return;

		setTimeout(() => {
			if (!this._ismounted)
				return;

			this.setState({ splashScreenAnimationEnded: true });
		}, 1000);

		this.setState({ appLoadingStatus: LoadingStatus.Completed });

		// Requests that can resolve after displaying the app.
		this.fetchInformationTexts();
	}

	componentWillUnmount() {
		this._ismounted = false;
		this.stopListeningToHistoryChanges();
	}

	// Gets called everytime a prop changes, allowing to update
	// the component's state depending on old and new prop values.
	static getDerivedStateFromProps(nextProps, previousState) {
		const { location } = nextProps;
		const { currentPathname } = previousState;

		const nextState = {};

		// Determines if the pathname has changed, play transition then.
		if (location.pathname !== currentPathname)
			nextState.currentPathname = location.pathname;

		return nextState;
	}

	render() {
		const {
			props: { location },
			state: {
				theme,
				userData,
				houseList,
				contactData,
				adCategories,
				houseDetails,
				supportedCities,
				supportedThemes,
				filterPreference,
				booleanSelectors,
				appLoadingStatus,
				informationTexts,
				houseListFilters,
				isFilterModalOpen,
				filteredHouseList,
				supportedLanguages,
				bookmarkDictionary,
				supportedHouseStatus,
				authenticationStatus,
				houseListFiltersEnabled,
				supportedPropertyTypes,
				splashScreenAnimationEnded,
				isLoadingFilteredHouseList,
			},
		} = this;

		let splashScreen = null;
		let content = null;
		let navigation = null;

		if (!splashScreenAnimationEnded || appLoadingStatus !== LoadingStatus.Completed)
			splashScreen = <SplashScreen key="splash-screen" readyToDisplayApp={appLoadingStatus === LoadingStatus.Completed} />;

		if (appLoadingStatus === LoadingStatus.Completed) {
			if (authenticationStatus === AuthenticationStatus.Authenticated && houseList != null) {
				navigation = <NavBar routes={authenticatedRoutes}/>;

				content = (
					<Container disableGutters>
						<ApplyFiltersModal
							isOpen={isFilterModalOpen}
							activeFilters={houseListFilters}
							supportedCities={supportedCities}
							filterPreference={filterPreference}
							onApply={this.updateHouseListFilters}
							supportedPropertyTypes={supportedPropertyTypes}
							onClose={() => this.setState({ isFilterModalOpen: false })}
							isAuthenticated={authenticationStatus === AuthenticationStatus.Authenticated}
						/>
						<Container className="appContainer">
							<Switch>
								{authenticatedRoutes.map(route => (
									<Route
										key={route.key}
										path={route.path}
										exact={route.exact}
										// component={route.Component}
										render={props => (
											<route.Component
												{...props}
												theme={theme}
												userData={userData}
												houseList={houseList}
												contactData={contactData}
												adCategories={adCategories}
												houseDetails={houseDetails}
												onSignedOut={this.onSignedOut}
												switchTheme={this.switchTheme}
												supportedCities={supportedCities}
												supportedThemes={supportedThemes}
												patchUserData={this.patchUserData}
												houseListFilters={houseListFilters}
												informationTexts={informationTexts}
												booleanSelectors={booleanSelectors}
												filterPreference={filterPreference}
												switchLanguage={this.switchLanguage}
												toggleBookmark={this.toggleBookmark}
												filteredHouseList={filteredHouseList}
												onAccountEdited={this.onAccountEdited}
												getHouseDetails={this.getHouseDetails}
												bookmarkDictionary={bookmarkDictionary}
												supportedLanguages={supportedLanguages}
												authenticationStatus={authenticationStatus}
												supportedHouseStatus={supportedHouseStatus}
												onCredentialsChanged={this.onCredentialsChanged}
												houseListFiltersEnabled={houseListFiltersEnabled}
												clearHouseListFilters={this.clearHouseListFilters}
												supportedPropertyTypes={supportedPropertyTypes}
												updateHouseListFilters={this.updateHouseListFilters}
												toggleHouseListFilters={this.toggleHouseListFilters}
												switchPushNotifications={this.switchPushNotifications}
												isLoadingFilteredHouseList={isLoadingFilteredHouseList}
												openHouseListFilterModal={this.openHouseListFilterModal}
												switchEmailNotifications={this.switchEmailNotifications}
											/>
										)}
									/>
								))}
								<Redirect
									key="auto-redirect"
									to={{
										pathname: '/houses',
										state: { from: location },
									}}
								/>
							</Switch>
						</Container>
					</Container>
				);
			} else {
				content = (
					<Container className="visitorRouteContainer">
						<Switch>
							{visitorRoutes.map(route => (
								<Route
									key={route.key}
									path={route.path}
									exact={route.exact}
									render={props => (
										<route.Component
											{...props}
											onSignedIn={this.onSignedIn}
											switchLanguage={this.switchLanguage}
											supportedLanguages={supportedLanguages}
											getPreferencesForNewUser={this.getPreferencesForNewUser}
										/>
									)}
								/>
							))}
							<Redirect
								key="auto-redirect"
								to={{
									pathname: visitorRoutes[0].href,
									state: { from: location },
								}}
							/>
						</Switch>
					</Container>
				);
			}
		}

		return (
			<AppTheme theme={theme}>
				<main>
					{splashScreen}
					{content}
					{navigation}
				</main>
			</AppTheme>
		);
	}


	// --- Working methods
	// TODO ERROR MANAGEMENT OF EVERY METHOD MAKING USE OF THE API.
	async fetchLanguages() {
		if (!this._ismounted || this.state.supportedLanguages != null)
			return;

		const getSupportedLanguagesResponse = await API.getSupportedLanguages();

		const promises = [];
		const supportedLanguages = [];
		let resources = getSupportedLanguagesResponse.data.reduce((result, { label, url }) => {
			const splitUrl = url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			supportedLanguages.push({ id, label });

			result[label] = { id, translation: {} };

			promises.push(API.get(url));

			return result;
		}, {});

		console.log('Supported languages', supportedLanguages);

		await this.setState({ supportedLanguages: Object.freeze(supportedLanguages) });

		const getTranslationResponses = await Promise.all(promises);

		resources = Object.freeze(getTranslationResponses.reduce((result, { data }) => {
			const { languages: label } = data;

			// Takes out the database collection name parameter,
			// to not mistakenly use it as a translation key.
			delete data.languages;

			// Removes the keys with empty (null or undefined) values
			// so that i18n uses the fallback language for those keys.
			result[label].translation = Object.freeze({ ...removeNullParametersFromObject(data) });

			return result;
		}, resources));

		console.log('Translations', resources);

		await i18n.init({
			...i18nDefaultConfiguration,
			resources,
			lng: sessionStorage.getItem(StorageKey.PreferredLanguage) || 'nl',
		});
	}

	async fetchBooleanSelectors() {
		if (!this._ismounted || this.state.supportedThemes != null)
			return;

		// The theme is currently saved using a boolean table "yes_no",
		// "ja" or "yes" meaning dark mode, "nee" or "no" meaning light mode.
		const getBooleanSelectorsResponse = await API.getBooleanSelectors();

		const promises = [];
		let booleanSelectors = getBooleanSelectorsResponse.data.reduce((result, { label, url }) => {
			const splitUrl = url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			result[label] = { id, label };

			promises.push(API.get(url));

			return result;
		}, {});

		const getSelectorTranslationsResponses = await Promise.all(promises);

		booleanSelectors = Object.freeze(getSelectorTranslationsResponses.reduce((result, { data }) => {
			const { yes_no: label } = data;

			delete data.yes_no;

			result[label] = Object.freeze({ ...result[label], ...data });

			return result;
		}, booleanSelectors));

		console.log('Boolean selectors', booleanSelectors);

		await this.setState({ booleanSelectors });
	}

	async fetchThemes() {
		if (!this._ismounted || this.state.supportedThemes != null)
			return;

		// The theme is currently saved using a boolean table "yes_no",
		// "ja" or "yes" meaning dark mode, "nee" or "no" meaning light mode.
		const getSupportedThemesResponse = await API.getSupportedThemes();

		const supportedThemes = getSupportedThemesResponse.data.reduce((result, { label, url }) => {
			const splitUrl = url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			if (label === 'ja')
				result.push({ id, label: ThemeKey.Dark });
			else
				result.push({ id, label: ThemeKey.Light });

			return result;
		}, []);

		console.log('Supported themes', supportedThemes);

		await this.setState({ supportedThemes: Object.freeze(supportedThemes) });
	}

	async fetchSupportedCities() {
		if (!this._ismounted || this.state.supportedCities != null)
			return;

		const getSupportedCitiesResponse = await API.getSupportedCities();

		const supportedCities = getSupportedCitiesResponse.data.reduce((result, { label, url }) => {
			const splitUrl = url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			result[id] = { id, label };

			return result;
		}, {});

		console.log('Supported cities', supportedCities);

		await this.setState({ supportedCities: Object.freeze(supportedCities) });
	}

	async fetchSupportedHouseStatus() {
		if (!this._ismounted || this.state.supportedHouseStatus != null)
			return;

		const getSupportedHouseStatusResponse = await API.getSupportedHouseStatus();

		const promises = [];
		let supportedHouseStatus = getSupportedHouseStatusResponse.data.reduce((result, { label, url }) => {
			const splitUrl = url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			result[label] = { id, label };

			promises.push(API.get(url));

			return result;
		}, {});

		const getStatusTranslationsResponses = await Promise.all(promises);

		supportedHouseStatus = getStatusTranslationsResponses.reduce((result, { data }) => {
			const { status: label } = data;

			delete data.status;

			result[label] = Object.freeze({ ...result[label], ...data });

			return result;
		}, supportedHouseStatus);

		console.log('Supported house status', supportedHouseStatus);

		await this.setState({ supportedHouseStatus: Object.freeze(supportedHouseStatus) });
	}

	async fetchSupportedPropertyTypes() {
		if (!this._ismounted || this.state.supportedCities != null)
			return;

		const getSupportedPropertyTypesResponse = await API.getSupportedPropertyTypes();

		const promises = [];
		let supportedPropertyTypes = getSupportedPropertyTypesResponse.data.reduce((result, { label, url }) => {
			const splitUrl = url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			result[label] = { id, label };

			promises.push(API.get(url));

			return result;
		}, {});

		const getPropertyTypesTranslationsResponses = await Promise.all(promises);

		supportedPropertyTypes = getPropertyTypesTranslationsResponses.reduce((result, { data }) => {
			const { soort_object: label } = data;
			const { id } = result[label];

			delete data.soort_object;

			result[id] = Object.freeze({ ...result[label], ...data });

			delete result[label];

			return result;
		}, supportedPropertyTypes);

		console.log('Supported Property Types', supportedPropertyTypes);

		await this.setState({ supportedPropertyTypes: Object.freeze(supportedPropertyTypes) });
	}

	async fetchContactData() {
		if (!this._ismounted || this.state.contactData != null)
			return;

		const getContactDataResponse = await API.getContactData();

		const promises = [];
		let contactData = getContactDataResponse.data.reduce((result, { label, url }) => {
			const splitUrl = url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			result[label] = { id, href: null };

			promises.push(API.get(url));

			return result;
		}, {});

		const getContactDetailsResponses = await Promise.all(promises);

		contactData = Object.freeze(getContactDetailsResponses.reduce((result, { data }) => {
			const { contact_data: label, nl } = data;

			result[label].href = nl;

			return result;
		}, contactData));

		console.log('Contact Data', contactData);

		await this.setState({ contactData });
	}

	async fetchAdCategories() {
		if (!this._ismounted || this.state.adCategories != null)
			return;

		const getAdCategoriesResponse = await API.getAdCategories();

		if (!this._ismounted)
			return;

		const promises = [];
		let adCategories = getAdCategoriesResponse.data.reduce((result, { label, url }) => {
			const splitUrl = url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			result[label] = { id };

			promises.push(API.get(url));

			return result;
		}, {});

		const getAdCategoryResponses = await Promise.all(promises);

		if (!this._ismounted)
			return;

		adCategories = Object.freeze(getAdCategoryResponses.reduce((result, { data }) => {
			const { ad_categories: label } = data;

			delete data.ad_categories;

			result[label] = { ...result[label], ...data };

			return result;
		}, adCategories));

		console.log('Ad Categories', adCategories);

		await this.setState({ adCategories });
	}

	async fetchInformationTexts() {
		if (!this._ismounted || this.state.informationTexts != null)
			return;

		const getInformationTextsResponse = await API.getInformationTexts();

		if (!this._ismounted)
			return;

		const promises = [];
		let informationTexts = getInformationTextsResponse.data.reduce((result, { label, url }) => {
			const splitUrl = url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			result[label] = { id, label };

			promises.push(API.get(url));

			return result;
		}, {});

		const getInformationTextTranslationsResponses = await Promise.all(promises);

		if (!this._ismounted)
			return;

		informationTexts = Object.freeze(getInformationTextTranslationsResponses.reduce((result, { data }) => {
			const { texts: label } = data;

			delete data.texts;

			result[label] = Object.freeze({ ...result[label], ...data });

			return result;
		}, informationTexts));

		console.log('Information texts', informationTexts);

		await this.setState({ informationTexts: Object.freeze(informationTexts) });
	}

	async fetchUserData() {
		if (!this._ismounted)
			return;

		const json = getStoredSessionCredentials();

		if (json == null) {
			await this.setState({ authenticationStatus: AuthenticationStatus.Visitor });
			return;
		}

		const { username, password } = json;

		const response = await API.getUserFromCredentials(username, password);

		if (!this._ismounted)
			return;

		if (response.data == null || response.data.length <= 0) {
			// Something went wrong, maybe the user has been deleted or his
			// credentials changed, the stored credentials should be discarded.

			console.warn('Could not retrieve user data from stored credentials, continuing as visitor.');

			sessionStorage.removeItem(StorageKey.UserCredentials);
			await this.setState({ authenticationStatus: AuthenticationStatus.Visitor });
			return;
		}

		const userData = formatUserData(response.data[0]);

		console.log('Retrieved user session', userData);

		await this.setState({
			userData,
			authenticationStatus: AuthenticationStatus.Authenticated
		});

		this.applyUserPreferences(userData);

		await this.fetchUserFilterPreference();
	}

	async fetchUserFilterPreference() {
		if (!this._ismounted || this.state.filterPreference != null)
			return;

		if (this.state.authenticationStatus !== AuthenticationStatus.Authenticated)
			return;

		const { userData } = this.state;

		const response = await API.getFilterPreferenceByUserID(userData.id);
		console.log('Get user filter preference response', response);

		if (!this._ismounted || response.status !== 200 || response.data == null) {
			console.warn('Cannot update state with user filter preference.');
			return;
		}

		if (response.data.length <= 0) {
			console.log('User never created default filter preference.');
			return;
		}

		const {
			url,
			plaats,
			soort_object,
			koopprijs_min,
			koopprijs_max,
			aantal_slaapkamers_min,
		} = response.data[0];

		const filterPreference = {
			id: getIdFromApiUrl(url),
			koopprijs_min: koopprijs_min == null ? null : Number.parseInt(koopprijs_min, 10),
			koopprijs_max: koopprijs_max == null ? null : Number.parseInt(koopprijs_max, 10),
			aantal_slaapkamers_min: aantal_slaapkamers_min == null ? null : Number.parseInt(aantal_slaapkamers_min, 10),
			plaats: plaats == null ? null : plaats.map(item => getIdFromApiUrl(item.url)),
			soort_object: soort_object == null ? null : soort_object.map(item => getIdFromApiUrl(item.url)),
		};

		await this.setState({ filterPreference });
		await this.updateHouseListFilters(filterPreference);
	}

	async fetchHouses() {
		if (!this._ismounted)
			return;

		const response = await API.getHouses();

		if (!this._ismounted)
			return;

		const houseList = response.data.reduce((result, currentItem) => {
			const splitUrl = currentItem.url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			result.push({
				id,
				...currentItem,
			});

			return result;
		}, []);

		this.setState({ houseList });
	}

	async fetchFilteredHouses(filters) {
		if (!this._ismounted)
			return;

		await this.setState({ isLoadingFilteredHouseList: true });

		const queryParameters = [];

		if (filters.koopprijs_min != null || filters.koopprijs_max != null) {
			queryParameters.push({
				queryParameter: 'koopprijs',
				value: `${RangeOperator.between.min}${filters.koopprijs_min}${RangeOperator.between.max}${filters.koopprijs_max}`
			});
		}

		if (filters.plaats != null && filters.plaats.length > 0) {
			queryParameters.push({
				queryParameter: 'plaats',
				value: filters.plaats.length > 1 ? `${RangeOperator.within}(${filters.plaats.join(',')})` : filters.plaats[0],
			});
		}

		if (filters.soort_object != null && filters.soort_object.length > 0) {
			queryParameters.push({
				queryParameter: 'soort_object',
				value: filters.soort_object.length > 1 ? `${RangeOperator.within}(${filters.soort_object.join(',')})` : filters.soort_object[0],
			});
		}

		if (filters.aantal_slaapkamers_min != null) {
			queryParameters.push({
				queryParameter: 'aantal_slaapkamers',
				value: `${ComparisonOperator.GreaterEqual}${filters.aantal_slaapkamers_min}`
			});
		}

		try {
			const response = await API.getHouses(queryParameters);

			if (!this._ismounted)
				return;

			const filteredHouseList = response.data.reduce((result, currentItem) => {
				const splitUrl = currentItem.url.split('/');
				const id = splitUrl[splitUrl.length - 1];

				result.push({
					id,
					...currentItem,
				});

				return result;
			}, []);

			await this.setState({ filteredHouseList });
		} catch (e) {
			console.exception(e);

			if (!this._ismounted)
				return;

			// TODO Add filterHouseListError ?
			this.setState({ filteredHouseList: [] });
		}
	}

	async getHouseDetails(id) {
		if (!this._ismounted)
			return;

		// Sets the loading house data to an empty object.
		this.setState(({ houseDetails }) => {
			houseDetails[id] = {};
			return { houseDetails };
		});

		const response = await API.getHouse(id);

		// console.log(`House ${id}`, response.data);

		if (!this._ismounted)
			return;

		this.setState(({ houseDetails }) => {
			houseDetails[id] = response.data;
			houseDetails[id].label = response.data.wonen;
			return { houseDetails };
		});
	}

	async patchUserData(changes) {
		console.log('Patching user data with', changes);

		const {
			userData,
			authenticationStatus,
		} = this.state;

		if (authenticationStatus !== AuthenticationStatus.Authenticated) {
			console.warn('Cannot update user data while not signed in.');
			return;
		}

		const response = await API.patchUser(userData.id, changes);

		console.log('Patch user response', response);

		if (!this._ismounted)
			return;

		if (response.data.success == null || response.data.success !== 1) {
			console.error('Failed to patch user.');
			return;
		}

		this.fetchUserData();
	}

	toggleBookmark(houseID) {
		const {
			bookmarkDictionary,
			authenticationStatus,
		} = this.state;

		const nextBookmarkDictionary = { ...bookmarkDictionary };
		if (nextBookmarkDictionary[houseID] == null)
			nextBookmarkDictionary[houseID] = houseID;
		else
			delete nextBookmarkDictionary[houseID];

		if (authenticationStatus !== AuthenticationStatus.Authenticated) {
			this.setState({ bookmarkDictionary: nextBookmarkDictionary });
			return;
		}

		this.patchUserData({ bookmarks: Object.keys(nextBookmarkDictionary) });
	}

	openHouseListFilterModal() {
		this.setState({ isFilterModalOpen: true });
	}

	async toggleHouseListFilters() {
		if (!this._ismounted)
			return;

		await this.setState(({ houseListFiltersEnabled }) => ({
			houseListFiltersEnabled: !houseListFiltersEnabled,
		}));
	}

	clearHouseListFilters() {
		this.updateHouseListFilters(null);
	}

	// Updates the state with new filters and updates the user's filter preferences if necessary.
	async updateHouseListFilters(filters, updatePreference = false) {
		if (!this._ismounted)
			return;

		if (filters == null || filters === {}) {
			await this.setState({
				filteredHouseList: [],
				houseListFilters: null,
				houseListFiltersEnabled: true,
				isLoadingFilteredHouseList: false,
			});

			return;
		}

		this.fetchFilteredHouses(filters);

		await	this.setState({
			houseListFilters: filters,
			houseListFiltersEnabled: true,
			isLoadingFilteredHouseList: false,
		});

		if (!this._ismounted || !updatePreference || filters == null || this.state.authenticationStatus !== AuthenticationStatus.Authenticated)
			return;

		const { userData, filterPreference } = this.state;

		if (filterPreference == null) {
			const postResponse = await API.postFilterPreference(userData.id, {
				...filters,
				zoekopdrachten: `filter preference of user ${userData.id}`,
			});

			console.log('Post filter preference response', postResponse);

			if (!this._ismounted || postResponse.status !== 201 || postResponse.data == null || isStringNullOrEmpty(postResponse.data.href)) {
				console.warn('Could not update the state with the newly created filter preferences.');
				return;
			}

			const id = getIdFromApiUrl(postResponse.data.href);

			await this.setState({
				filterPreference: {
					...filters,
					id,
				},
			});
			return;
		}

		const patchResponse = await API.patchFilterPreference(filterPreference.id, filters);
		console.log('Patch filter preference response', patchResponse);

		if (!this._ismounted || patchResponse.status !== 200) {
			console.warn('Could not update the state with the updated filter preferences.');
			return;
		}

		await this.setState(previousState => ({
			filterPreference: {
				...previousState,
				...filters,
			}
		}));
	}

	switchLanguage(languageToApply) {
		if (!this._ismounted || i18n.language === languageToApply.label)
			return;

		const { authenticationStatus } = this.state;

		if (authenticationStatus === AuthenticationStatus.Authenticated) {
			this.patchUserData({ taal: languageToApply.id });
			return;
		}

		i18n.changeLanguage(languageToApply.label);
		sessionStorage.setItem(StorageKey.PreferredLanguage, languageToApply.label);
	}

	switchTheme(themeToApply) {
		if (!this._ismounted || this.state.theme === themeToApply.label)
			return;

		const { authenticationStatus } = this.state;

		if (authenticationStatus === AuthenticationStatus.Authenticated) {
			this.patchUserData({ nightmode: themeToApply.id });
			return;
		}

		this.setState({ theme: themeToApply.label });
		sessionStorage.setItem(StorageKey.Theme, themeToApply.label);
	}

	switchEmailNotifications(booleanID) {
		const { authenticationStatus } = this.state;

		if (authenticationStatus !== AuthenticationStatus.Authenticated)
			return;

		if (this.state.userData.emailNotifications === booleanID)
			return;

		this.patchUserData({ emailmeldingen: booleanID });
	}

	switchPushNotifications(booleanID) {
		const { authenticationStatus } = this.state;

		if (authenticationStatus !== AuthenticationStatus.Authenticated)
			return;

		if (this.state.userData.pushNotifications === booleanID)
			return;

		this.patchUserData({ pushmeldingen: booleanID });
	}

	applyUserPreferences(userData) {
		if (!this._ismounted)
			return;

		if (i18n.language !== userData.language) {
			i18n.changeLanguage(userData.language);
			sessionStorage.setItem(StorageKey.PreferredLanguage, userData.language);
		}

		this.setState((currentState) => {
			const changes = {
				bookmarkDictionary: { ...userData.bookmarkDictionary }
			};

			if (currentState.theme !== userData.theme) {
				changes.theme = userData.theme;
				sessionStorage.setItem(StorageKey.Theme, userData.theme);
			}

			return changes;
		});
	}

	// Aggregates the current preferences set in the app to include
	// them in the user data if the user is a visitor and registers
	// after having saved bookmarks, changed language, etc.
	getPreferencesForNewUser() {
		const {
			theme,
			booleanSelectors,
			supportedLanguages,
			bookmarkDictionary,
		} = this.state;

		const language = supportedLanguages.find(({ label }) => label === i18n.language);

		return {
			pushmeldingen: booleanSelectors.nee.id,
			emailmeldingen: booleanSelectors.nee.id,
			bookmarks: Object.keys(bookmarkDictionary),
			taal: language != null ? language.id : supportedLanguages[0].id,
			nightmode: theme === ThemeKey.Dark ? booleanSelectors.ja.id : booleanSelectors.nee.id,
		};
	}


	// --- Event methods
	onLocationChanged(location, action) {
		if (!this._ismounted || location == null || isStringNullOrEmpty(location.pathname))
			return;

		const path = location.pathname.substring(1).split('/', 1);
		if (path.length < 1 || isStringNullOrEmpty(path[0]))
			return;

		const nextPath = path[0];

		const { currentPath } = this.state;

		if (currentPath === nextPath)
			return;

		this.setState({ currentPath: nextPath }, () => window.scrollTo(0, 0));
	}

	async onSignedIn(credentials, rawUserData) {
		if (!this._ismounted)
			return;

		sessionStorage.setItem(StorageKey.UserCredentials, JSON.stringify(credentials));

		const userData = formatUserData(rawUserData);

		await this.setState({
			userData,
			authenticationStatus: AuthenticationStatus.Authenticated,
		});

		if (!this._ismounted)
			return;

		this.applyUserPreferences(userData);

		this.fetchHouses();
		this.fetchUserFilterPreference();
	}

	async onAccountEdited(rawUserData) {
		if (!this._ismounted)
			return;

		const userData = formatUserData(rawUserData);

		this.onCredentialsChanged(userData.users, null);

		await this.setState({ userData });
	}

	onCredentialsChanged(username, password) {
		if (!this._ismounted)
			return;

		if (this.state.authenticationStatus !== AuthenticationStatus.Authenticated) {
			console.error('Session credentials cannot be set or edited when not signed in.');
			return;
		}

		if (!isStringNullOrEmpty(username) && !isStringNullOrEmpty(password)) {
			sessionStorage.setItem(StorageKey.UserCredentials, JSON.stringify({
				username,
				password
			}));
			return;
		}

		const json = JsonExtension.parseOrNull(sessionStorage.getItem(StorageKey.UserCredentials));

		if (json == null) {
			console.error('Session credentials could not be parsed.');
			return;
		}

		const { username: storedUsername, password: storedPassword } = json;

		if (!isStringNullOrEmpty(password)) {
			sessionStorage.setItem(StorageKey.UserCredentials, JSON.stringify({
				username: storedUsername,
				password
			}));
			return;
		}

		sessionStorage.setItem(StorageKey.UserCredentials, JSON.stringify({
			username,
			password: storedPassword
		}));
	}

	onSignedOut() {
		if (!this._ismounted)
			return;

		sessionStorage.removeItem(StorageKey.UserCredentials);

		this.setState({
			userData: null,
			filteredHouseList: [],
			houseListFilters: null,
			filterPreference: null,
			bookmarkDictionary: {},
			houseListFiltersEnabled: true,
			isLoadingFilteredHouseList: false,
			authenticationStatus: AuthenticationStatus.Visitor,
		}, () => this.props.history.push('/houses'));
	}
}

App.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
		listen: PropTypes.func.isRequired,
	}),
	location: PropTypes.object,
};

export default withRouter(App);
