// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { makeStyles } from '@material-ui/core/styles';

// --- External Components
import Typography from '@material-ui/core/Typography';

// --- Style
import './DetailChip.sass';

const useStyles = makeStyles(theme => ({
	root: {
		background: theme.darkMode ? '#161616' : '#e0e0e0',
		// display: 'grid',
		// gridAutoFlow: 'column',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center',
		height: 28,
		borderRadius: 4,
		lineHeight: 'normal',
		padding: '0 4px',
		width: props => props.fullWidth ? '100%' : 'normal',
	},
	couple: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0 8px',
	},
	icon: {
		fontSize: 20,
		color: theme.palette.text.secondary,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 8,
	},
}));


const DetailChip = ({
	icons,
	labels,
	fullWidth,
	...props
}) => {
	const classes = useStyles({ fullWidth });

	const couples = [];
	for (let i = 0; i < labels.length; i++) {
		let icon = null;
		if (icons != null && icons.length >= i && icons[i] != null) {
			icon = (
				<div className={classes.icon}>
					{icons[i]}
				</div>
			);
		}

		couples.push(
			<div
				key={`couple-${i}`}
				className={classes.couple}
			>
				{icon}
				<Typography variant="body2" color="textSecondary">
					{labels[i]}
				</Typography>
			</div>
		);
	}

	return (
		<div className={classes.root} {...props}>
			{couples}
		</div>
	);
};

DetailChip.propTypes = {
	icons: PropTypes.array,
	fullWidth: PropTypes.bool,
	labels: PropTypes.array.isRequired,
};

DetailChip.defaultProps = {
	fullWidth: false,
};

export default DetailChip;
