// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const getFilterPreference = (id, contextConfiguration) => axios.get(
	`/zoekopdrachten/${id}&fields=*`,
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default getFilterPreference;
