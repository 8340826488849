// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { makeStyles } from '@material-ui/core/styles';

// --- Style
import './ItemHeadLine.sass';

const useStyles = makeStyles(theme => ({
	title: {
		display: 'inline',
		fontSize: '20px',
		fontWeight: 400,
		padding: 0,
		// margin: '0 0.5em 0 0',
		margin: 0,
		color: theme.palette.text.primary,
	},
	subtitle: {
		display: 'inline',
		fontSize: '20px',
		fontWeight: 400,
		padding: 0,
		margin: 0,
		color: theme.palette.text.secondary,
	},
	separator: {
		display: 'inline',
		fontSize: '20px',
		fontWeight: 400,
		padding: 0,
		margin: 0,
		color: theme.palette.text.secondary,
	},
}));


const ItemHeadLine = (props) => {
	const {
		icon,
		title,
		subtitle,
		separator
	} = props;

	const classes = useStyles(props);

	return (
		<div className="itemHeadLine">
			<div className="iconContainer">
				{icon}
			</div>
			<div className="titleContainer">
				<h2 className={classes.title}>
					{title}
					{separator != null ? (
						<pre className={classes.separator}>
							{`${separator} `}
						</pre>
					) : null}
				</h2>
				<h4 className={classes.subtitle}>
					{subtitle}
				</h4>
			</div>
		</div>
	);
};

ItemHeadLine.propTypes = {
	icon: PropTypes.any,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	separator: PropTypes.string,
};

export default ItemHeadLine;
