// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { red } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

// --- Logic
import ThemeKey from 'logic/enums/ThemeKey';


const fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";

const lightTheme = createMuiTheme({
	darkMode: false,
	palette: {
		type: 'light',
		primary: {
			main: '#406089',
		},
		secondary: {
			main: '#6784a8',
		},
		error: {
			main: red.A700,
		},
		background: {
			default: '#EEEEEA',
			secondary: '#EEEDE7',
			paper: '#ffffff',
			backgroundPaper: '#e0e0e0',
			danger: '#c30000',
			aroundDanger: '#c3000024',
			bookmark: '#00000080',
			bookmarkActive: '#000000b4',
		},
		text: {
			primary: '#0f0f0f',
			secondary: '#76787A',
			danger: '#c30000',
			inDanger: '#ffffff',
			bookmark: '#ebebeb80',
			bookmarkActive: '#f2da00',
		},
	},
	typography: {
		fontFamily,
	},
});

const darkTheme = createMuiTheme({
	darkMode: true,
	palette: {
		type: 'dark',
		primary: {
			main: '#647ea1', // #4b70a0
		},
		secondary: {
			main: '#647ea1', // #6784a8
		},
		error: {
			main: red.A400,
		},
		background: {
			default: '#0a0a08',
			secondary: '#00000000',
			paper: '#323232',
			backgroundPaper: '#161616',
			danger: '#c30000',
			aroundDanger: '#c3000032',
			bookmark: '#00000080',
			bookmarkActive: '#000000b4',
		},
		text: {
			primary: '#f0f0f0',
			secondary: '#bebebe',
			danger: '#c30000',
			inDanger: '#f0f0f0',
			bookmark: '#ebebeb80',
			bookmarkActive: '#f2da00',
		},
	},
	typography: {
		fontFamily,
	},
});

const AppTheme = ({ theme, children }) => (
	<ThemeProvider theme={theme === ThemeKey.Light ? lightTheme : darkTheme}>
		<CssBaseline/>
		{children}
	</ThemeProvider>
);

AppTheme.propTypes = {
	children: PropTypes.any,
	theme: PropTypes.string.isRequired,
};

// AppTheme.defaultProps = {
// 	theme: ThemeKey.Light,
// };

export default AppTheme;
