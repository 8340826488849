// --- Requests
import get from './requests/get';
import getHouse from './requests/getHouse';
import getHouses from './requests/getHouses';
import getAdData from './requests/getAdData';
import getContactData from './requests/getContactData';
import getAdCategories from './requests/getAdCategories';
import checkIfUserExists from './requests/checkIfUserExists';
import getSupportedCities from './requests/getSupportedCities';
import getSupportedThemes from './requests/getSupportedThemes';
import getBooleanSelectors from './requests/getBooleanSelectors';
import getFilterPreference from './requests/getFilterPreference';
import getInformationTexts from './requests/getInformationTexts';
import getSupportedLanguages from './requests/getSupportedLanguages';
import getUserFromCredentials from './requests/getUserFromCredentials';
import getSupportedHouseStatus from './requests/getSupportedHouseStatus';
import getSupportedPropertyTypes from './requests/getSupportedPropertyTypes';
import getFilterPreferenceByUserID from './requests/getFilterPreferenceByUserID';

import patchUser from './requests/patchUser';
import patchFilterPreference from './requests/patchFilterPreference';

import postUser from './requests/postUser';
import postFilterPreference from './requests/postFilterPreference';
import postPasswordReset from './requests/postPasswordReset';

import deleteUser from './requests/deleteUser';


const baseURL = 'https://api.brown.dutchbackend.nl/maasenpeel';

export const commonConfiguration = Object.freeze({
	baseURL,
});

// API
export default {
	get,
	getHouse,
	getHouses,
	getAdData,
	getContactData,
	getAdCategories,
	checkIfUserExists,
	getSupportedCities,
	getSupportedThemes,
	getInformationTexts,
	getBooleanSelectors,
	getFilterPreference,
	getSupportedLanguages,
	getUserFromCredentials,
	getSupportedHouseStatus,
	getSupportedPropertyTypes,
	getFilterPreferenceByUserID,

	patchUser,
	patchFilterPreference,

	postUser,
	postFilterPreference,
	postPasswordReset,
	
	deleteUser,
};
