// --- Framework
import React from 'react';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Icon from '@material-ui/core/Icon';

// --- Components
import SignIn from 'visual/components/routes/SignIn';
import Houses from 'visual/components/routes/Houses';
import Register from 'visual/components/routes/Register';
import Bookmarks from 'visual/components/routes/Bookmarks';
import Preferences from 'visual/components/routes/Preferences';
import Information from 'visual/components/routes/Information';
import PasswordReset from 'visual/components/routes/PasswordReset';

export const authenticatedRoutes = [
	{
		key: TranslationKey.navbar_explorer,
		href: '/houses',
		path: '/houses/:id?',
		exact: true,
		icon: <Icon>home</Icon>,
		Component: Houses,
		order: 0,
	},
	{
		key: TranslationKey.navbar_favorites,
		href: '/bookmarks',
		path: '/bookmarks/:id?',
		exact: true,
		icon: <Icon>grade</Icon>,
		Component: Bookmarks,
		order: 1,
	},
	{
		key: TranslationKey.navbar_preferences,
		href: '/preferences',
		path: '/preferences',
		exact: false,
		icon: <Icon>person</Icon>,
		Component: Preferences,
		order: 2,
	},
	{
		key: TranslationKey.navbar_information,
		href: '/information',
		path: '/information',
		exact: false,
		icon: <Icon>help</Icon>,
		Component: Information,
		order: 3,
	},
];

export const visitorRoutes = [
	{
		key: TranslationKey.sign_in,
		href: '/sign-in',
		path: '/sign-in',
		exact: true,
		Component: SignIn,
	},
	{
		key: TranslationKey.register,
		href: '/register',
		path: '/register',
		exact: true,
		Component: Register,
	},
	{
		key: TranslationKey.password_reset,
		href: '/reset_password',
		path: '/reset_password',
		exact: true,
		Component: PasswordReset,
	},
];
