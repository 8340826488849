// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

// --- Style
const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0 0',
		height: '100%',
	},
	text: {
		padding: 0,
		textAlign: 'justify',
	},
}));


const EndOfListCard = ({
	itemsCount,
	isFilteredList,
	translationKey,
	displayAsAlert,
	translationKeyWhenEmpty,
	translationKeyWhenFiltered,
	...props
}) => {
	const classes = useStyles(props);

	const { t } = useTranslation();

	let text = null;
	if (isFilteredList)
		text = <Trans i18nKey={translationKeyWhenFiltered} values={{ itemsCount }} />;
	else if (itemsCount > 0)
		text = <Trans i18nKey={translationKey} values={{ itemsCount }} />;
	else
		text = <Trans i18nKey={translationKeyWhenEmpty} />;

	let content = null;
	if (displayAsAlert != null) {
		content = (
			<Alert severity={displayAsAlert}>
				{text}
			</Alert>
		);
	} else {
		content = (
			<Typography
				variant="body2"
				color="textSecondary"
				className={classes.text}
			>
				{text}
			</Typography>
		);
	}

	return (
		<div className={classes.root}>
			{content}
		</div>
	);
};

EndOfListCard.propTypes = {
	displayAsAlert: PropTypes.string,
	translationKey: PropTypes.string,
	itemsCount: PropTypes.number.isRequired,
	isFilteredList: PropTypes.bool.isRequired,
	translationKeyWhenEmpty: PropTypes.string,
	translationKeyWhenFiltered: PropTypes.string,
};

EndOfListCard.defaultProps = {
	translationKey: TranslationKey.end_of_list_default,
	translationKeyWhenEmpty: TranslationKey.end_of_list_empty,
	translationKeyWhenFiltered: TranslationKey.end_of_list_filtered,
};

export default EndOfListCard;
