// --- Framework
import React from 'react';

// --- External components
import Icon from '@material-ui/core/Icon';
// import SvgIcon from '@material-ui/core/SvgIcon';

// --- Assets
// import WhatsappIcon from 'assets/icons/whatsapp.svg';


const AdIcon = props => ({
	fallback: <Icon {...props} >chevron_right</Icon>,
	ARCHITECTUUR: <Icon {...props} >gite</Icon>,
	BOUWEN: <Icon {...props} >construction</Icon>,
	FINANCIEREN: <Icon {...props} >account_balance_wallet</Icon>, // savings paid account_balance_wallet
	INRICHTING: <Icon {...props} >outdoor_grill</Icon>,
	ONDERHOUD: <Icon {...props} >plumbing</Icon>,
	VERHUIZEN: <Icon {...props} >local_shipping</Icon>,
});

// The `props` parameter can be `{ style: { opacity: 0.5, fontSize: 20 } }`.
export const getAdvertisementIcon = (label, props) => {
	let icon = AdIcon(props)[label];

	if (icon == null)
		icon = AdIcon(props).fallback;

	return icon;
};

export default AdIcon;
