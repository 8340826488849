// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { makeStyles } from '@material-ui/core/styles';

// --- Style
const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
		overflow: 'visible',
		display: 'flex',
		justifyContent: props => props.alignment,
		alignItems: 'center',
		padding: '0 14px',
		height: props => props.zeroHeight ? 0 : 'normal',
		width: '100%',
	},
	status: {
		height: '19px',
		color: 'white',
		padding: '0 4px',
		display: 'flex',
		alignItems: 'center',
		borderRadius: '4px',
		border: `2px solid ${theme.palette.background.paper}`,
		background: props => props.color,
	},
	label: {
		textAlign: 'center',
		fontSize: '11px',
		textTransform: 'capitalize',
	},
}));


const ItemStatus = (props) => {
	const {
		label,
	} = props;

	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<div className={classes.status}>
				<span className={classes.label}>
					{label}
				</span>
			</div>
		</div>
	);
};

ItemStatus.propTypes = {
	label: PropTypes.string,
	color: PropTypes.string,
	zeroHeight: PropTypes.bool,
	alignment: PropTypes.string,
};

ItemStatus.defaultProps = {
	zeroHeight: true,
	alignment: 'flex-end',
};

export default ItemStatus;
