// OBSOLETE - Use Intl.NumberFormat instead.
// Places thousand separators where needed in a string representing
// a large number, for example, '1000000' would become '1.000.000'.
export function insertThousandsSeparator(text, separator = '.') {
	// Source: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch06s12.html
	return text.replace(/[0-9](?=(?:[0-9]{3})+(?![0-9]))/g, `$&${separator}`);
}

export const isStringNullOrEmpty = str => !str || str.length <= 0;

// Verifies if a string contains any matching occurence of another (smaller) string,
// the test is case insensitive, a word in lowercase will match its uppercase counterpart.
export function containsText(textToSearch, textToFind) {
	return `/${textToFind}/i`.test(textToSearch);
}

// Verifies that a text is at least formatted in a way that resembles an email address.
export function isValidEmailAddress(text) {
	return /.+@.+\..+/.test(text);
}

// Verifies that a text is at least formatted in a way that resembles an email address.
export function isValidPhoneNumber(text) {
	return /^\+?[0-9]{11,12}$/.test(text);
}

// Generates a random "GUID-like" 16 characters string.
export function randomGuid() {
	return 'xxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		// eslint-disable-next-line no-bitwise
		const r = Math.random() * 16 | 0;
		// eslint-disable-next-line no-bitwise, no-mixed-operators
		const v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

export function getIdFromApiUrl(url) {
	if (isStringNullOrEmpty(url))
		return '';

	const splitUrl = url.split('/');

	if (splitUrl.length <= 0)
		return '';

	return splitUrl[splitUrl.length - 1];
}
