// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import Color from 'logic/enums/Color';
import ContactIcon from 'logic/enums/ContactIcon';
import TranslationKey from 'logic/enums/TranslationKey';
import { getAdvertisementIcon } from 'logic/enums/AdIcons';
import HouseStatusColor from 'logic/enums/HouseStatusColor';
import { getIdFromApiUrl, isStringNullOrEmpty } from 'logic/stringOperations';

// --- External components
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ImageGallery from 'react-image-gallery';
import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';
import ListItem from '@material-ui/core/ListItem';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// --- Components
import ImageView from 'visual/components/_/media/ImageView';
import ItemStatus from 'visual/components/_/item/ItemStatus';
import ItemHeadLine from 'visual/components/_/item/ItemHeadLine';
import RequestInformationModal from 'visual/components/modals/RequestInformationModal';

// --- Assets
import PlotIcon from 'assets/icons/plot.svg';
import BedroomIcon from 'assets/icons/bedroom.svg';
import SurfaceIcon from 'assets/icons/surface.svg';
import LocationIcon from 'assets/icons/location.svg';

// --- Style
import './HouseInspectorStyle.sass';

const styles = theme => ({
	bookmarkButton: {
		padding: '6px',
		color: theme.palette.text.bookmark,
		background: theme.palette.background.bookmark,
		'&:hover': {
			background: theme.palette.background.bookmarkActive,
		},
	},
	bookmarkButtonActive: {
		padding: '6px',
		color: theme.palette.text.bookmarkActive,
		background: theme.palette.background.bookmarkActive,
		'&:hover': {
			background: theme.palette.background.bookmark,
		},
	},
	locationIcon: {
		padding: 0,
		margin: 0,
		fontSize: 36,
		color: theme.palette.text.primary,
	},
	priceIcon: {
		padding: 0,
		margin: 0,
		fontSize: 30,
		color: theme.palette.text.primary,
	},
});

const fallbackImage = '/images/image-placeholder.png';


class HouseInspector extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			imageViewIndex: 0,
			showImageView: false,
			informationRequest: null,
		};

		this.onClose = this.onClose.bind(this);
		this.renderNoData = this.renderNoData.bind(this);
	}

	onClose() {
		const { onClose } = this.props;

		this.setState({
			imageViewIndex: 0,
			showImageView: false,
		}, onClose);
	}

	renderNoData(translationKey) {
		const { t, onClose } = this.props;

		return (
			<div className="modal houseInspector">
				<DialogTitle disableTypography className="modalBar">
					{onClose ? (
						<Button
							onClick={onClose}
							aria-label="close"
							startIcon={<Icon>chevron_left</Icon>}
						>
							{t(TranslationKey.back)}
						</Button>
					) : null}
					<Box flexGrow={1} component="span"/>
				</DialogTitle>
				<div className="modalBody">
					<div style={{ padding: '64px 32px', textAlign: 'center' }}>
						{t(translationKey)}
					</div>
				</div>
			</div>
		);
	}

	render() {
		const {
			props: {
				t,
				id,
				i18n,
				classes,
				onClose,
				houseList,
				bookmarked,
				contactData,
				adCategories,
				toggleBookmark,
				supportedHouseStatus,
				supportedPropertyTypes,
			},
			state: {
				showImageView,
				imageViewIndex,
				informationRequest,
			}
		} = this;

		if (houseList == null)
			return this.renderNoData(TranslationKey.loading);

		const details = houseList.find(house => house.id === id);

		if (details == null)
			return this.renderNoData(TranslationKey.house_not_found);

		let street = '-';
		if (!isStringNullOrEmpty(details.straat)) {
			street = details.straat;

			if (!isStringNullOrEmpty(details.huisnummer))
				street += ` ${details.huisnummer}`;
		}

		const city = details.plaats != null && details.plaats.length > 0 ? details.plaats[0].label : '-';

		let price = '-';
		if (details.koopprijs != null) {
			price = new Intl.NumberFormat(i18n.language, {
				// style: 'currency',
				// currency: 'EUR',
				// minimumFractionDigits: 0,
				// notation: 'compact',
			}).format(details.koopprijs);
		}

		let pictures = [];

		if (details.foto != null) {
			if (details.foto instanceof Array) {
				pictures = details.foto.map(url => ({
					original: url,
					thumbnail: url,
				}));
			} else if (typeof details.foto === 'string') {
				pictures.push({
					original: details.foto,
					thumbnail: details.foto,
				});
			}
		}

		if (details.hoofdfoto != null) {
			pictures.unshift({
				original: details.hoofdfoto,
				thumbnail: details.hoofdfoto,
			});
		}

		let statusComponent = null;
		if (details.status != null && details.status.length > 0) {
			const status = details.status[0].label;

			statusComponent = (
				<ItemStatus
					alignment="center"
					zeroHeight={false}
					color={HouseStatusColor[status] || Color.Grey80}
					label={supportedHouseStatus[status][i18n.language]}
				/>
			);
		}

		let descriptionComponent = null;
		if (!isStringNullOrEmpty(details.flyer_tekst)) {
			descriptionComponent = (
				<Typography
					variant="body2"
					style={{ textAlign: 'justify' }}
					dangerouslySetInnerHTML={{ __html: details.flyer_tekst }}
				/>
			);
		} else {
			descriptionComponent = (
				<Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic' }}>
					{t(TranslationKey.description_caveat_empty)}
				</Typography>
			);
		}

		let publicationDate = '-';
		if (details.publicatiedatum != null) {
			const options = {
				year: 'numeric',
				month: 'long',
				day: 'numeric'
			};

			publicationDate = new Date(details.publicatiedatum).toLocaleDateString(i18n.language, options);
		}

		let plot = '-';
		if (!isStringNullOrEmpty(details.perceeloppervlakte))
			plot = <>{details.perceeloppervlakte} m<sup>2</sup></>;

		let surface = '-';
		if (!isStringNullOrEmpty(details.woonoppervlakte))
			surface = <>{details.woonoppervlakte} m<sup>2</sup></>;

		let typeOfProperty = '-';
		if (details.soort_object != null && details.soort_object.length > 0 && details.soort_object[0] != null && !isStringNullOrEmpty(details.soort_object[0].url)) {
			const typeOfPropertyID = getIdFromApiUrl(details.soort_object[0].url);

			if (!isStringNullOrEmpty(typeOfPropertyID))
				typeOfProperty = supportedPropertyTypes[typeOfPropertyID][i18n.language] || supportedPropertyTypes[typeOfPropertyID].nl;
		}

		const detailChips = (
			<Grid
				item
				container
				spacing={0}
				direction="row"
				style={{ padding: 0, margin: '22px 0' }}
			>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="inspector-detail">
						<SvgIcon
							key="plot-icon"
							component={PlotIcon}
							style={{ fontSize: 26, opacity: 0.8 }}
						/>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.house_plot_area)}
						</Typography>
						<span
							className="value"
						>
							{plot}
						</span>
					</div>
				</Grid>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="inspector-detail">
						<SvgIcon
							key="surface-icon"
							component={SurfaceIcon}
							style={{ fontSize: 26, opacity: 0.8 }}
						/>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.house_surface_area)}
						</Typography>
						<span
							className="value"
						>
							{surface}
						</span>
					</div>
				</Grid>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="inspector-detail">
						<Icon
							key="type-of-property-icon"
							style={{ opacity: 0.8 }}
						>
							apartment
						</Icon>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.house_type_of_property)}
						</Typography>
						<span
							className="value"
							style={{ textTransform: 'capitalize' }}
						>
							{typeOfProperty}
						</span>
					</div>
				</Grid>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="inspector-detail">
						<SvgIcon
							key="bedrooms-icon"
							component={BedroomIcon}
							style={{ fontSize: 24, opacity: 0.8 }}
						/>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.house_bedrooms)}
						</Typography>
						<span
							className="value"
						>
							{details.aantal_slaapkamers || '-'}
						</span>
					</div>
				</Grid>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="inspector-detail">
						<Icon
							key="floors-icon"
							style={{ opacity: 0.8 }}
						>
							layers
						</Icon>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.house_floors)}
						</Typography>
						<span
							className="value"
						>
							{details.aantal_woonlagen || '-'}
						</span>
					</div>
				</Grid>
				<Grid
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className="inspector-detail">
						<Icon
							key="publication-date-icon"
							style={{ opacity: 0.8 }}
						>
							more_time
						</Icon>
						<Typography
							className="label"
							variant="subtitle2"
						>
							{t(TranslationKey.house_publication_date)}
						</Typography>
						<span
							className="value"
						>
							{publicationDate}
						</span>
					</div>
				</Grid>
			</Grid>
		);

		let contactButtons = null;
		if (contactData != null) {
			contactButtons = (
				<Grid
					item
				>
					<Grid
						container
						spacing={1}
						direction="row"
					>
						{Object.keys(contactData).map(label => (
							<Grid
								item
								key={contactData[label].id}
							>
								<Button
									size="small"
									target="_blank"
									color="primary"
									disableElevation
									variant="contained"
									rel="noopener noreferrer"
									href={contactData[label].href}
									startIcon={ContactIcon[label]}
								>
									{t(TranslationKey[label])}
								</Button>
							</Grid>
						))}
					</Grid>
				</Grid>
			);
		}

		let adsComponent = null;
		if (adCategories != null) {
			adsComponent = (
				<Grid item>
					<RequestInformationModal
						t={t}
						i18n={i18n}
						adCategory={informationRequest}
						toggleBookmark={toggleBookmark}
						houseAddress={`${street}, ${city}`}
						onClose={() => this.setState({ informationRequest: null })}
					/>
					<Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic' }}>
						{t(TranslationKey.advertising_header)}
					</Typography>
					<List
						title="list"
						component="nav"
						aria-label="third party advertising list"
					>
						<Divider />
						{Object.keys(adCategories).map(label => (
							<ListItem
								button
								component="a"
								target="_blank"
								rel="noopener noreferrer"
								key={adCategories[label].id}
								onClick={() => this.setState({ informationRequest: adCategories[label] })}
							>
								<ListItemIcon>
									{getAdvertisementIcon(label, { color: 'secondary', style: { fontSize: 24 } })}
								</ListItemIcon>
								<ListItemText primary={adCategories[label][i18n.language]} />
							</ListItem>
						))}
						<Divider />
					</List>
				</Grid>
			);
		}

		return (
			<div className="modal houseInspector">
				{pictures != null && pictures.length > 0 && showImageView && (
					<ImageView
						image={pictures[imageViewIndex].original}
						onClose={() => this.setState({ showImageView: false })}
					/>
				)}
				<DialogTitle disableTypography className="modalBar">
					{onClose ? (
						<Button
							onClick={onClose}
							aria-label="close"
							startIcon={<Icon>chevron_left</Icon>}
						>
							{t(TranslationKey.back)}
						</Button>
					) : null}
					<Box flexGrow={1} component="span"/>
					{bookmarked ? (
						<IconButton
							color="primary"
							aria-label="bookmark"
							onClick={() => toggleBookmark(id)}
							className={classes.bookmarkButtonActive}
						>
							<Icon>grade</Icon>
						</IconButton>
					) : (
						<IconButton
							aria-label="bookmark"
							className={classes.bookmarkButton}
							onClick={() => toggleBookmark(id)}
						>
							<Icon>grade</Icon>
						</IconButton>
					)}
				</DialogTitle>
				<Container
					disableGutters
					className="modalBody"
				>
					<div style={{ marginBottom: 8 }}>
						{
							pictures.length > 0 ? (
								<ImageGallery
									lazyLoad
									infinite={false}
									items={pictures}
									slideDuration={250}
									slideOnThumbnailOver
									showPlayButton={false}
									showFullscreenButton={false}
									useBrowserFullscreen={false}
									onClick={() => this.setState({ showImageView: true })}
									onBeforeSlide={nextIndex => this.setState({ imageViewIndex: nextIndex })}
								/>
							) : (
								<Box
									display="flex"
									alignItems="center"
									flexDirection="vertical"
									justifyContent="center"
									style={{ height: '40vh', background: '#00000018' }}
								>
									<Typography
										variant="body1"
										color="textSecondary"
										style={{ fontStyle: 'italic' }}
									>
										{t(TranslationKey.no_picture_available)}
									</Typography>
								</Box>
							)
						}
					</div>
					{statusComponent}
					<div className="content">
						<div className="headers">
							<ItemHeadLine
								icon={<SvgIcon component={LocationIcon} className={classes.locationIcon}/>}
								title={street}
								subtitle={city}
								separator=","
							/>
							<ItemHeadLine
								icon={<span className={classes.priceIcon}>€</span>}
								title={price}
							/>
						</div>
						<Grid
							container
							spacing={4}
							direction="column"
						>
							{detailChips}
							<Grid item>
								{descriptionComponent}
							</Grid>
							{contactButtons}
							{adsComponent}
						</Grid>
					</div>
				</Container>
				<div className="footer">
					<Button
						onClick={onClose}
						className="button"
					>
						{t(TranslationKey.back)}
					</Button>
				</div>
			</div>
		);
	}
}

HouseInspector.propTypes = {
	id: PropTypes.string,
	houseList: PropTypes.array,
	t: PropTypes.func.isRequired,
	contactData: PropTypes.object,
	adCategories: PropTypes.object,
	i18n: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	bookmarked: PropTypes.bool.isRequired,
	toggleBookmark: PropTypes.func.isRequired,
	supportedHouseStatus: PropTypes.object.isRequired,
	supportedPropertyTypes: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
)(HouseInspector);
