// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- External components
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

// --- Components
import { Transition } from 'App';

// --- Style
const styles = theme => ({});


class ConfirmationModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {
			props: {
				t,
				isOpen,
				classes,
				onClose,
				cancelText,
				confirmText,
				contentText,
				onCompleted,
			}
		} = this;

		return (
			<Dialog
				fullWidth
				open={isOpen}
				maxWidth="sm"
				onClose={onClose}
				TransitionComponent={Transition}
				aria-labelledby="confirmation-modal-title"
				// aria-describedby="confirmation-modal-body"
			>
				<div className="modal">
					<DialogTitle id="confirmation-modal-title">
						{contentText}
					</DialogTitle>
					<DialogActions>
						<Button
							onClick={onClose}
						>
							{cancelText}
						</Button>
						<Button
							disableElevation
							variant="contained"
							color="primary"
							onClick={() => {
								onClose();
								onCompleted();
							}}
						>
							{confirmText}
						</Button>
					</DialogActions>
				</div>
			</Dialog>
		);
	}
}

ConfirmationModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	onCompleted: PropTypes.func.isRequired,
	cancelText: PropTypes.string.isRequired,
	confirmText: PropTypes.string.isRequired,
	contentText: PropTypes.string.isRequired,
};

ConfirmationModal.defaultProps = {
	isOpen: false,
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(ConfirmationModal);
