// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const getInformationTexts = contextConfiguration => axios.get(
	'/texts',
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default getInformationTexts;
