// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const checkIfUserExists = (username, contextConfiguration) => {
	const escapedUsername = encodeURI(username);

	const config = {
		...commonConfiguration,
		...contextConfiguration,
	};

	// config.headers = {
	// 	'Cache-Control': 'no-cache',
	// };

	return axios.get(
		`/users?users=${escapedUsername}`,
		config
	).catch(onRequestError);
};

export default checkIfUserExists;
