// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { useTranslation, Trans } from 'react-i18next';

// --- Logic
import ContactIcon from 'logic/enums/ContactIcon';
import TranslationKey from 'logic/enums/TranslationKey';

// --- External components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// --- Components
import ShowMore from 'visual/components/_/text/ShowMore';


const Information = ({
	contactData,
	informationTexts,
}) => {
	const { t, i18n } = useTranslation();

	let contactButtons = null;
	if (contactData != null) {
		contactButtons = (
			<Grid
				item
				container
				spacing={1}
				direction="row"
			>
				{Object.keys(contactData).map(label => (
					<Grid
						item
						key={contactData[label].id}
					>
						<Button
							size="small"
							target="_blank"
							color="primary"
							disableElevation
							variant="contained"
							rel="noopener noreferrer"
							href={contactData[label].href}
							startIcon={ContactIcon[label]}
						>
							{t(TranslationKey[label])}
						</Button>
					</Grid>
				))}
			</Grid>
		);
	}

	let privacyPolicyText = null;
	let contactText = null;
	if (informationTexts != null) {
		if (informationTexts.contact != null) {
			contactText = (
				<Grid item>
					<Typography
						variant="h5"
						component="h2"
						style={{ textTransform: 'capitalize', marginBottom: 14 }}
					>
						{t(TranslationKey.contact)}
					</Typography>
					<Typography
						variant="body2"
						component="span"
					>
						<Trans>
							{informationTexts.contact[i18n.language]}
						</Trans>
					</Typography>
				</Grid>
			);
		}

		if (informationTexts.privacy_policy != null) {
			privacyPolicyText = (
				<Grid item>
					<Typography
						variant="h5"
						component="h2"
						style={{ textTransform: 'capitalize' }}
					>
						{t(TranslationKey.privacy_policy)}
					</Typography>
					<ShowMore
						t={t}
						collapsedHeight={240}
					>
						<Typography
							variant="body2"
							component="span"
						>
							<Trans>
								{informationTexts.privacy_policy[i18n.language]}
							</Trans>
						</Typography>
					</ShowMore>
				</Grid>
			);
		}
	}

	return (
		<Container
			disableGutters
			className="page"
		>
			<Grid
				container
				spacing={3}
				direction="column"
			>
				{contactText}
				{contactButtons}
				<Grid item />
				{privacyPolicyText}
			</Grid>
		</Container>
	);
};

Information.propTypes = {
	contactData: PropTypes.object,
	informationTexts: PropTypes.object,
};

export default Information;
