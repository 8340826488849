// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const deleteUser = (id, contextConfiguration) => axios.delete(
	`/users/${id}`,
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default deleteUser;
