// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';

const baseURL = 'https://maasenpeel.dutchbackend.nl';
const commonConfigurationPasswordReset = Object.freeze({
	baseURL,
});


const postPasswordReset = (email, contextConfiguration) => axios.get(
	`/wachtwoord_reset_handling?email=${email}`,
	{
		...commonConfigurationPasswordReset,
		...contextConfiguration,
	}
).catch(onRequestError);

export default postPasswordReset;
