import ThemeKey from 'logic/enums/ThemeKey';
import StorageKey from './enums/StorageKey';
import { isStringNullOrEmpty } from './stringOperations';
import AuthenticationStatus from './enums/AuthenticationStatus';
import * as JsonExtension from './jsonOperations';


export function formatUserData(data) {
	const {
		url,
		taal,
		nightmode,
		bookmarks,
		pushmeldingen,
		emailmeldingen,
	} = data;

	let splitUrl = url.split('/');
	const id = splitUrl[splitUrl.length - 1];

	let language = 'en';
	if (taal == null || taal.length <= 0 || taal[0].label == null)
		console.warn('Unable to get the preferred language out of the user data.');
	else
		language = taal[0].label;

	let theme = ThemeKey.Light;
	if (nightmode == null || nightmode.length <= 0 || nightmode[0].label == null)
		console.warn('Unable to get the preferred theme out of the user data.');
	else {
		const nightmodeLabel = nightmode[0].label;

		if (nightmodeLabel === 'ja' || nightmodeLabel === 'yes')
			theme = ThemeKey.Dark;
	}

	// const bookmarkIDs = bookmarks.reduce((result, currentItem) => {
	// 	splitUrl = currentItem.url.split('/');
	// 	const bookmarkID = splitUrl[splitUrl.length - 1];
	//
	// 	result.push(bookmarkID);
	// 	return result;
	// }, []);

	const bookmarkDictionary = bookmarks.reduce((result, currentItem) => {
		splitUrl = currentItem.url.split('/');
		const bookmarkID = splitUrl[splitUrl.length - 1];

		// Uses an object instead of an array to avoid duplicates.
		result[bookmarkID] = bookmarkID;

		return result;
	}, {});

	let pushNotifications = null;
	if (pushmeldingen == null || pushmeldingen.length <= 0 || pushmeldingen[0].url == null)
		console.warn('Unable to get the preferred push notifications out of the user data.');
	else {
		splitUrl = pushmeldingen[0].url.split('/');
		pushNotifications = splitUrl[splitUrl.length - 1];
	}

	let emailNotifications = null;
	if (emailmeldingen == null || emailmeldingen.length <= 0 || emailmeldingen[0].url == null)
		console.warn('Unable to get the preferred email notifications out of the user data.');
	else {
		splitUrl = emailmeldingen[0].url.split('/');
		emailNotifications = splitUrl[splitUrl.length - 1];
	}

	// TODO It's a security hazard to have the password in the response of GET users request,
	//  this does not guaranty that the password won't still be around in the memory.
	delete data.password;

	return {
		...data,
		id,
		theme,
		language,
		pushNotifications,
		emailNotifications,
		bookmarkDictionary,
	};
}

export function getStoredSessionCredentials() {
	const storedCredentials = sessionStorage.getItem(StorageKey.UserCredentials);

	if (isStringNullOrEmpty(storedCredentials))
		return null;

	const json = JsonExtension.parseOrNull(storedCredentials);

	if (json == null || json.username == null || json.password == null) {
		console.warn('The stored credentials are invalid, clearing cache.');

		sessionStorage.removeItem(StorageKey.UserCredentials);
		return null;
	}

	return json;
}
