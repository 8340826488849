// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import Color from 'logic/enums/Color';
import TranslationKey from 'logic/enums/TranslationKey';
import HouseStatusColor from 'logic/enums/HouseStatusColor';
import { MIN_PRICE_RANGE, MAX_PRICE_RANGE, PRICE_RANGE_STEP } from 'logic/constants/FilterConstants';

// --- External components
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// --- Components
import { Transition } from 'App';
import Dot from 'visual/components/_/dot/Dot';

// --- Style
import './ApplyFiltersModal.sass';

const styles = theme => ({
	chip: {
		textTransform: 'capitalize',
		margin: theme.spacing(0.5),
	},
	clearButton: {
		color: theme.palette.text.danger,
		borderColor: theme.palette.background.danger,
		'&:hover': {
			color: theme.palette.text.danger,
			borderColor: theme.palette.background.danger,
		},
	}
});


export const countActiveFilters = (filters) => {
	if (filters == null || filters === {})
		return 0;

	let count = 0;
	if (filters.koopprijs_min != null || filters.koopprijs_max != null)
		count++;
	if (filters.plaats != null && filters.plaats.length > 0)
		count++;
	if (filters.soort_object != null && filters.soort_object.length > 0)
		count++;
	if (filters.aantal_slaapkamers_min != null)
		count++;

	return count;
};

const noCitySelected = Object.freeze({ id: '', label: '' });

const defaultState = Object.freeze({
	minimumPrice: MIN_PRICE_RANGE,
	maximumPrice: MAX_PRICE_RANGE,
	tempMinimumPrice: MIN_PRICE_RANGE,
	tempMaximumPrice: MAX_PRICE_RANGE,
	hasChanged: false,
	cityInput: '',
	cities: [],
	propertyTypes: [],
	minimumBedroomCount: 0,
});

class ApplyFiltersModal extends React.Component {
	constructor(props) {
		super(props);

		const {
			supportedCities,
			alwaysUpdateFilterPreference,
		} = props;

		this.state = {
			open: false,
			activeFilterCount: -1,
			cityOptions: [...Object.values(supportedCities)],
			updateFilterPreference: alwaysUpdateFilterPreference,
			...defaultState,
		};

		this.onClose = this.onClose.bind(this);
		this.onClear = this.onClear.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onRestore = this.onRestore.bind(this);
		this.onCityRemoved = this.onCityRemoved.bind(this);
		this.onCitySelected = this.onCitySelected.bind(this);
		this.onPropertyTypeRemoved = this.onPropertyTypeRemoved.bind(this);
		this.onPropertyTypeSelected = this.onPropertyTypeSelected.bind(this);
	}

	// Gets called everytime a prop changes, allowing to update
	// the component's state depending on old and new prop/state values.
	static getDerivedStateFromProps(nextProps, previousState) {
		const {
			isOpen,
			activeFilters,
			supportedCities,
		} = nextProps;

		const {
			cities,
			minimumPrice,
			maximumPrice,
			open: wasOpen,
			propertyTypes,
			minimumBedroomCount,
		} = previousState;

		// The goal here is to reset the state if the appliedFilters prop is set to null
		// from higher up in the hierarchy. `this.onClear` method is a punctual call where
		// the state can be edited as usual, but if the filters are edited from say App.jsx
		// (which is the actual bearer of appliedFilters props, it's one of App.jsx' state)
		// then this component has to figure out from its props that something changed
		// so that it updates its state accordingly.

		if (isOpen === wasOpen)
			return null;

		const nextState = { open: isOpen };

		if (isOpen === false)
			return nextState;

		const nextActiveFilterCount = countActiveFilters(activeFilters);

		nextState.activeFilterCount = nextActiveFilterCount;

		if (nextActiveFilterCount === 0 || activeFilters.plaats == null) {
			cities.length = 0;
			nextState.cities = cities;
		} else if (activeFilters.plaats !== cities.map(city => city.id))
			nextState.cities = activeFilters.plaats.map(id => supportedCities[id]);

		if (nextActiveFilterCount === 0 || activeFilters.soort_object == null) {
			propertyTypes.length = 0;
			nextState.propertyTypes = propertyTypes;
		} else if (activeFilters.soort_object !== propertyTypes)
			nextState.propertyTypes = [...activeFilters.soort_object];

		if (nextActiveFilterCount === 0 || activeFilters.koopprijs_min == null) {
			nextState.minimumPrice = MIN_PRICE_RANGE;
			nextState.tempMinimumPrice = MIN_PRICE_RANGE;
		} else if (activeFilters.koopprijs_min !== minimumPrice) {
			nextState.minimumPrice = activeFilters.koopprijs_min;
			nextState.tempMinimumPrice = activeFilters.koopprijs_min;
		}

		if (nextActiveFilterCount === 0 || activeFilters.koopprijs_max == null) {
			nextState.maximumPrice = MAX_PRICE_RANGE;
			nextState.tempMaximumPrice = MAX_PRICE_RANGE;
		} else if (activeFilters.koopprijs_max !== maximumPrice) {
			nextState.maximumPrice = activeFilters.koopprijs_max;
			nextState.tempMaximumPrice = activeFilters.koopprijs_max;
		}

		if (nextActiveFilterCount === 0 || activeFilters.aantal_slaapkamers_min == null)
			nextState.minimumBedroomCount = 0;
		else if (activeFilters.aantal_slaapkamers_min !== minimumBedroomCount)
			nextState.minimumBedroomCount = activeFilters.aantal_slaapkamers_min;

		return nextState;
	}


	// --- Event methods
	onCitySelected(event, selectedCity) {
		if (selectedCity == null)
			return;

		this.setState(({ cities }) => {
			cities.push(selectedCity);
			return { hasChanged: true, cities, cityInput: '' };
		});
	}

	onCityRemoved(index) {
		this.setState(({ cities }) => {
			cities.splice(index, 1);
			return { hasChanged: true, cities };
		});
	}

	onPropertyTypeSelected(event) {
		const { target: { value: selectedPropertyType } } = event;

		this.setState(({ propertyTypes }) => {
			propertyTypes.push(selectedPropertyType);
			return { hasChanged: true, propertyTypes };
		});
	}

	onPropertyTypeRemoved(index) {
		this.setState(({ propertyTypes }) => {
			propertyTypes.splice(index, 1);
			return { hasChanged: true, propertyTypes };
		});
	}

	async onSubmit(event) {
		if (event != null)
			event.preventDefault();

		const {
			props: {
				onApply,
			},
			state: {
				cities,
				minimumPrice,
				maximumPrice,
				propertyTypes,
				updateFilterPreference,
				minimumBedroomCount,
			}
		} = this;

		const filters = {
			plaats: null,
			soort_object: null,
			koopprijs_min: null,
			koopprijs_max: null,
			aantal_slaapkamers_min: null,
		};

		if (cities.length > 0)
			filters.plaats = cities.map(city => city.id);

		if (propertyTypes.length > 0)
			filters.soort_object = [...propertyTypes];

		if (minimumPrice !== MIN_PRICE_RANGE || maximumPrice !== MAX_PRICE_RANGE) {
			filters.koopprijs_min = minimumPrice;
			filters.koopprijs_max = maximumPrice;
		}

		if (minimumBedroomCount > 0)
			filters.aantal_slaapkamers_min = minimumBedroomCount;

		await onApply(filters, updateFilterPreference);

		this.onClose();
	}

	onRestore() {
		const {
			props: {
				supportedCities,
				filterPreference,
				supportedPropertyTypes,
				alwaysUpdateFilterPreference,
			},
			state: {
				cities,
				minimumPrice,
				maximumPrice,
				propertyTypes,
				minimumBedroomCount,
			},
		} = this;

		const nextFilterCount = countActiveFilters(filterPreference);

		const nextState = {
			activeFilterCount: nextFilterCount,
			hasChanged: !alwaysUpdateFilterPreference,
			updateFilterPreference: alwaysUpdateFilterPreference,
		};

		if (nextFilterCount <= 0 || filterPreference.plaats == null) {
			cities.length = 0;
			nextState.cities = cities;
		} else
			nextState.cities = filterPreference.plaats.map(id => supportedCities[id]);

		if (nextFilterCount <= 0 || filterPreference.soort_object == null) {
			propertyTypes.length = 0;
			nextState.propertyTypes = propertyTypes;
		} else
			nextState.propertyTypes = Object.keys(supportedPropertyTypes).filter(id => filterPreference.soort_object.indexOf(id) >= 0);

		if (nextFilterCount <= 0 || filterPreference.koopprijs_min == null) {
			nextState.minimumPrice = MIN_PRICE_RANGE;
			nextState.tempMinimumPrice = MIN_PRICE_RANGE;
		} else {
			nextState.minimumPrice = filterPreference.koopprijs_min;
			nextState.tempMinimumPrice = filterPreference.koopprijs_min;
		}

		if (nextFilterCount <= 0 || filterPreference.koopprijs_max == null) {
			nextState.maximumPrice = MAX_PRICE_RANGE;
			nextState.tempMaximumPrice = MAX_PRICE_RANGE;
		} else {
			nextState.maximumPrice = filterPreference.koopprijs_max;
			nextState.tempMaximumPrice = filterPreference.koopprijs_max;
		}

		if (nextFilterCount <= 0 || filterPreference.aantal_slaapkamers_min == null)
			nextState.minimumBedroomCount = 0;
		else
			nextState.minimumBedroomCount = filterPreference.aantal_slaapkamers_min;

		this.setState(nextState);
	}

	onClear() {
		const {
			onApply,
			applyOnClear,
			alwaysUpdateFilterPreference,
		} = this.props;

		this.setState(({ cities, propertyTypes }) => {
			// Replacing the arrays to new ones doesn't trigger the related react
			// elements to re-render, emptying the existing array instances does.
			cities.length = 0;
			propertyTypes.length = 0;

			return {
				...defaultState,
				cities,
				propertyTypes,
				activeFilterCount: 0,
				hasChanged: !applyOnClear,
				updateFilterPreference: alwaysUpdateFilterPreference,
			};
		});

		if (!applyOnClear)
			return;

		onApply(null, false);

		this.onClose();
	}

	onClose() {
		const {
			props: {
				onClose,
				alwaysUpdateFilterPreference,
			},
		} = this;

		this.setState({
			hasChanged: false,
			updateFilterPreference: alwaysUpdateFilterPreference,
		});

		onClose();
	}


	render() {
		const {
			props: {
				t,
				i18n,
				isOpen,
				classes,
				isAuthenticated,
				supportedCities,
				filterPreference,
				supportedPropertyTypes,
				alwaysUpdateFilterPreference,
			},
			state: {
				cities,
				cityInput,
				hasChanged,
				cityOptions,
				minimumPrice,
				maximumPrice,
				propertyTypes,
				tempMinimumPrice,
				tempMaximumPrice,
				activeFilterCount,
				updateFilterPreference,
				minimumBedroomCount,
			}
		} = this;

		let priceRangeValue;
		if (minimumPrice === MIN_PRICE_RANGE && maximumPrice === MAX_PRICE_RANGE) {
			priceRangeValue = (
				<Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic' }}>
					{t(TranslationKey.modal_filters_select_price_empty)}
				</Typography>
			);
		} else if (maximumPrice === MAX_PRICE_RANGE) {
			const minimumPriceValue = new Intl.NumberFormat(i18n.language, {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0,
			}).format(minimumPrice);

			priceRangeValue = (
				<Typography variant="body1" color="primary">
					{`${minimumPriceValue} ${t(TranslationKey.minimum)}`}
				</Typography>
			);
		} else if (minimumPrice === MIN_PRICE_RANGE) {
			const maximumPriceValue = new Intl.NumberFormat(i18n.language, {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0,
			}).format(maximumPrice);

			priceRangeValue = (
				<Typography variant="body1" color="primary">
					{`${maximumPriceValue} ${t(TranslationKey.maximum)}`}
				</Typography>
			);
		} else {
			const minimumPriceValue = new Intl.NumberFormat(i18n.language, {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0,
			}).format(minimumPrice);

			const maximumPriceValue = new Intl.NumberFormat(i18n.language, {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0,
			}).format(maximumPrice);

			priceRangeValue = (
				<Typography variant="body1" color="primary">
					{`${t(TranslationKey.between)} ${minimumPriceValue} ${t(TranslationKey.and)} ${maximumPriceValue}`}
				</Typography>
			);
		}

		const priceSelector = (
			<Grid item style={{ marginBottom: '16px' }}>
				<FormControl
					fullWidth
					size="small"
					variant="outlined"
				>
					<Typography
						id="price-range-label"
						variant="subtitle1"
						style={{ paddingBottom: 8, fontWeight: 600 }}
					>
						{t(TranslationKey.modal_filters_select_price_label)}
					</Typography>
					<Grid
						container
						spacing={1}
						direction="row"
					>
						<Grid item>
							{priceRangeValue}
						</Grid>
						<Slider
							min={MIN_PRICE_RANGE}
							max={MAX_PRICE_RANGE}
							step={PRICE_RANGE_STEP}
							aria-labelledby="price-range-label"
							defaultValue={[MIN_PRICE_RANGE, MAX_PRICE_RANGE]}
							style={{ marginLeft: '10px', marginRight: '10px' }}
							getAriaLabel={index => index <= 0 ? 'minimum-price-selector' : 'maximum-price-selector'}
							value={[
								typeof minimumPrice === 'number' ? minimumPrice : MIN_PRICE_RANGE,
								typeof maximumPrice === 'number' ? maximumPrice : MAX_PRICE_RANGE
							]}
							onChange={(event, values) => {
								const [minValue, maxValue] = values;

								this.setState({
									hasChanged: true,
									minimumPrice: minValue,
									maximumPrice: maxValue,
									tempMinimumPrice: minValue,
									tempMaximumPrice: maxValue,
								});
							}}
						/>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size="small"
								maxLength="7"
								margin="dense"
								variant="outlined"
								value={tempMinimumPrice}
								label={t(TranslationKey.minimum)}
								onChange={({ target: { value } }) => {
									this.setState({ tempMinimumPrice: value });
								}}
								onBlur={() => {
									let finalMinimumPrice;
									if (tempMinimumPrice <= maximumPrice) {
										finalMinimumPrice = Math.max(tempMinimumPrice, MIN_PRICE_RANGE);

										this.setState({
											hasChanged: true,
											tempMinimumPrice: finalMinimumPrice,
											minimumPrice: finalMinimumPrice
										});
										return;
									}

									finalMinimumPrice = maximumPrice;
									const finalMaximumPrice = Math.min(tempMinimumPrice, MAX_PRICE_RANGE);

									this.setState({
										hasChanged: true,
										tempMinimumPrice: finalMinimumPrice,
										tempMaximumPrice: finalMaximumPrice,
										minimumPrice: finalMinimumPrice,
										maximumPrice: finalMaximumPrice,
									});
								}}
								inputProps={{
									type: 'number',
									min: MIN_PRICE_RANGE,
									max: MAX_PRICE_RANGE,
									step: PRICE_RANGE_STEP,
									'aria-labelledby': 'price-range-label',
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size="small"
								maxLength="7"
								margin="dense"
								variant="outlined"
								value={tempMaximumPrice}
								label={t(TranslationKey.maximum)}
								onChange={({ target: { value } }) => {
									this.setState({ tempMaximumPrice: value });
								}}
								onBlur={() => {
									let finalMaximumPrice;
									if (tempMaximumPrice >= minimumPrice) {
										finalMaximumPrice = Math.min(tempMaximumPrice, MAX_PRICE_RANGE);

										this.setState({
											hasChanged: true,
											tempMaximumPrice: finalMaximumPrice,
											maximumPrice: finalMaximumPrice
										});
										return;
									}

									finalMaximumPrice = minimumPrice;
									const finalMinimumPrice = Math.max(tempMaximumPrice, MIN_PRICE_RANGE);

									this.setState({
										hasChanged: true,
										tempMaximumPrice: finalMaximumPrice,
										tempMinimumPrice: finalMinimumPrice,
										maximumPrice: finalMaximumPrice,
										minimumPrice: finalMinimumPrice,
									});
								}}
								inputProps={{
									type: 'number',
									min: MIN_PRICE_RANGE,
									max: MAX_PRICE_RANGE,
									step: PRICE_RANGE_STEP,
									'aria-labelledby': 'price-range-label',
								}}
							/>
						</Grid>
					</Grid>
				</FormControl>
			</Grid>
		);

		let citySelector = null;
		if (supportedCities != null) {
			let selectedCities = null;
			if (cities.length > 0) {
				selectedCities = cities.map(({ id, label }, index) => (
					<Chip
						key={id}
						label={label}
						color="primary"
						variant="outlined"
						className={classes.chip}
						onDelete={() => this.onCityRemoved(index)}
					/>
				));
			} else {
				selectedCities = (
					<Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic' }}>
						{t(TranslationKey.modal_filters_select_city_empty)}
					</Typography>
				);
			}

			citySelector = (
				<Grid item style={{ marginBottom: '16px' }}>
					<Typography
						id="city-label"
						variant="subtitle1"
						style={{ paddingBottom: 8, fontWeight: 600 }}
					>
						{t(TranslationKey.modal_filters_select_city_label)}
					</Typography>
					<div style={{ paddingBottom: 12 }}>
						{selectedCities}
					</div>
					<Autocomplete
						fullWidth
						clearOnBlur
						size="small"
						autoHighlight
						value={null}
						inputValue={cityInput}
						id="select-filter-city"
						onChange={this.onCitySelected}
						getOptionLabel={option => option.label}
						options={cityOptions.filter(c => cities.indexOf(c) <= -1)}
						getOptionSelected={option => option.id === noCitySelected.id}
						onInputChange={(event, value) => this.setState({ cityInput: value })}
						renderInput={params => (
							<TextField
								{...params}
								size="small"
								variant="outlined"
								InputLabelProps={{ style: { color: '#808080' } }}
								label={t(TranslationKey.modal_filters_select_city_field)}
							/>
						)}
					/>
				</Grid>
			);
		}

		let propertyTypeSelector = null;
		if (supportedPropertyTypes != null) {
			let selectedPropertyTypes = null;
			if (propertyTypes.length > 0) {
				selectedPropertyTypes = propertyTypes.map((id, index) => (
					<Chip
						key={id}
						color="primary"
						variant="outlined"
						className={classes.chip}
						label={supportedPropertyTypes[id][i18n.language]}
						onDelete={() => this.onPropertyTypeRemoved(index)}
					/>
				));
			} else {
				selectedPropertyTypes = (
					<Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic' }}>
						{t(TranslationKey.modal_filters_select_property_types_empty)}
					</Typography>
				);
			}

			propertyTypeSelector = (
				<Grid item style={{ marginBottom: '16px' }}>
					<Typography
						id="property-types-label"
						variant="subtitle1"
						style={{ paddingBottom: 8, fontWeight: 600 }}
					>
						{t(TranslationKey.modal_filters_select_property_types_label)}
					</Typography>
					<div style={{ paddingBottom: 12 }}>
						{selectedPropertyTypes}
					</div>
					<FormControl
						fullWidth
						size="small"
						variant="outlined"
					>
						<Select
							value={''}
							displayEmpty
							id="select-filter-property-types"
							onChange={this.onPropertyTypeSelected}
							style={{ textTransform: 'capitalize', color: '#808080' }}
						>
							<MenuItem
								value=""
								style={{ textTransform: 'capitalize' }}
							>
								{t(TranslationKey.modal_filters_select_property_types_field)}
							</MenuItem>
							{
								Object.keys(supportedPropertyTypes)
									.filter(id => propertyTypes.indexOf(id) <= -1)
									.map(id => (
										<MenuItem
											key={id}
											value={id}
											style={{ textTransform: 'capitalize' }}
										>
											{supportedPropertyTypes[id][i18n.language] || supportedPropertyTypes[id].nl}
										</MenuItem>
									))
							}
						</Select>
					</FormControl>
				</Grid>
			);
		}

		let minimumBedroomCountValue;
		if (typeof minimumBedroomCount !== 'number' || minimumBedroomCount <= 0) {
			minimumBedroomCountValue = (
				<Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic' }}>
					{t(TranslationKey.modal_filters_select_minimum_bedroom_count_empty)}
				</Typography>
			);
		} else if (minimumBedroomCount >= 5) {
			minimumBedroomCountValue = (
				<Typography variant="body1" color="primary">
					{`${minimumBedroomCount}+`}
				</Typography>
			);
		} else {
			minimumBedroomCountValue = (
				<Typography variant="body1" color="primary">
					{minimumBedroomCount}
				</Typography>
			);
		}

		const minimumBedroomCountSelector = (
			<Grid item style={{ marginBottom: '16px' }}>
				<FormControl
					fullWidth
					size="small"
					variant="outlined"
				>
					<Typography
						id="minimum-bedroom-count-range-label"
						variant="subtitle1"
						style={{ paddingBottom: 8, fontWeight: 600 }}
					>
						{t(TranslationKey.modal_filters_select_minimum_bedroom_count_label)}
					</Typography>
					<Grid
						container
						spacing={1}
						direction="row"
					>
						<Grid item>
							{minimumBedroomCountValue}
						</Grid>
						<Slider
							min={0}
							max={5}
							step={1}
							defaultValue={0}
							style={{ marginLeft: '10px', marginRight: '10px' }}
							aria-labelledby="minimum-bedroom-count-range-label"
							getAriaLabel={() => 'minimum-bedroom-count-selector'}
							value={typeof minimumBedroomCount === 'number' ? minimumBedroomCount : 0}
							onChange={(event, value) => this.setState({
								hasChanged: true,
								minimumBedroomCount: value,
							})}
						/>
					</Grid>
				</FormControl>
			</Grid>
		);

		let updateFilterPreferenceCheckbox = null;
		if (isAuthenticated && !alwaysUpdateFilterPreference) {
			updateFilterPreferenceCheckbox = (
				<Grid item>
					<FormControlLabel
						label={t(TranslationKey.modal_filters_update_preference_checkbox)}
						control={
							<Checkbox
								color="primary"
								name="update-filter-preference"
								checked={updateFilterPreference}
								onChange={({ target: { checked } }) => this.setState({ updateFilterPreference: checked })}
							/>
						}
					/>
				</Grid>
			);
		}

		return (
			<Dialog
				fullScreen
				open={isOpen}
				TransitionComponent={Transition}
				aria-labelledby="apply-filter-modal-title"
				aria-describedby="apply-filter-modal-content"
			>
				<div className="modal">
					<DialogTitle
						disableTypography
						className="modalBar"
						id="apply-filter-modal-title"
					>
						<Button
							onClick={this.onClose}
							aria-label="close button"
							startIcon={<Icon>chevron_left</Icon>}
						>
							{t(TranslationKey.cancel)}
						</Button>
						<Typography
							variant="h6"
							className="modalTitle"
						>
							{t(TranslationKey.modal_filters_title)}
						</Typography>
						<Button
							size="small"
							variant="outlined"
							onClick={this.onClear}
							className={classes.clearButton}
							aria-label="clear filters button"
							disabled={activeFilterCount <= 0}
						>
							{t(TranslationKey.modal_filters_clear_button)}
						</Button>
					</DialogTitle>
					<Container
						noValidate
						maxWidth="xs"
						disableGutters
						component="form"
						onSubmit={this.onSubmit}
						id="apply-filter-modal-content"
						className="modalBody gridPadding"
					>
						<Grid
							container
							spacing={2}
							justify="center"
							direction="column"
							className="content"
							alignItems="stretch"
						>
							{priceSelector}
							{citySelector}
							{propertyTypeSelector}
							{minimumBedroomCountSelector}
							{updateFilterPreferenceCheckbox}
							<Grid item>
								<Button
									fullWidth
									size="large"
									type="submit"
									color="primary"
									disableElevation
									variant="contained"
									disabled={!(hasChanged || (updateFilterPreference && !alwaysUpdateFilterPreference))}
								>
									{!updateFilterPreference ? t(TranslationKey.modal_filters_apply_button) : t(TranslationKey.modal_filters_apply_and_save_button)}
								</Button>
							</Grid>
							{
								isAuthenticated && (
									<Grid item>
										<Button
											fullWidth
											size="small"
											variant="outlined"
											onClick={this.onRestore}
											disabled={filterPreference == null || (alwaysUpdateFilterPreference && !hasChanged)}
											aria-label="restore filter preference button"
										>
											{t(TranslationKey.modal_filters_restore_preference_button)}
										</Button>
									</Grid>
								)
							}
						</Grid>
					</Container>
					<div className="footer">
						<Button
							onClick={this.onClose}
							className="button"
						>
							{t(TranslationKey.cancel)}
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}
}

ApplyFiltersModal.propTypes = {
	isOpen: PropTypes.bool,
	t: PropTypes.func.isRequired,
	applyOnClear: PropTypes.bool,
	activeFilters: PropTypes.object,
	i18n: PropTypes.object.isRequired,
	onApply: PropTypes.func.isRequired,
	filterPreference: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
	alwaysUpdateFilterPreference: PropTypes.bool,
	supportedCities: PropTypes.object.isRequired,
	supportedPropertyTypes: PropTypes.object.isRequired,
};

ApplyFiltersModal.defaultProps = {
	isOpen: false,
	applyOnClear: true,
	activeFilters: null,
	filterPreference: null,
	alwaysUpdateFilterPreference: false,
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(ApplyFiltersModal);
