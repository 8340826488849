import { isStringNullOrEmpty } from '../stringOperations';
import { alphabeticalSort } from '../sortOperations';


const getSortingValue = (item, parameter) => {
	if (item == null || isStringNullOrEmpty(item[parameter]))
		return null;

	return item[parameter];
};

const SupportedSorting = Object.freeze({
	sort_none: {
		id: 'sort_none',
		sort: null
	},
	sort_newest: {
		id: 'sort_newest',
		sort: (a, b) => {
			const valueA = getSortingValue(a, 'publicatiedatum');
			const valueB = getSortingValue(b, 'publicatiedatum');

			if (valueA == null && valueB == null)
				return 1;
			if (valueA == null && valueB != null)
				return 1;
			if (valueA != null && valueB == null)
				return -1;

			return -alphabeticalSort(valueA, valueB);
		},
	},
	sort_price_high_low: {
		id: 'sort_price_high_low',
		sort: (a, b) => {
			const valueA = getSortingValue(a, 'koopprijs');
			const valueB = getSortingValue(b, 'koopprijs');

			if (valueA == null && valueB == null)
				return 1;
			if (valueA == null && valueB != null)
				return 1;
			if (valueA != null && valueB == null)
				return -1;

			return -alphabeticalSort(valueA, valueB);
		},
	},
	sort_price_low_high: {
		id: 'sort_price_low_high',
		sort: (a, b) => {
			const valueA = getSortingValue(a, 'koopprijs');
			const valueB = getSortingValue(b, 'koopprijs');

			if (valueA == null && valueB == null)
				return 1;
			if (valueA == null && valueB != null)
				return 1;
			if (valueA != null && valueB == null)
				return -1;

			return alphabeticalSort(valueA, valueB);
		},
	},
});

export default SupportedSorting;
