// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- IO
import API from 'io/API';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';
import { isStringNullOrEmpty, isValidEmailAddress, isValidPhoneNumber } from 'logic/stringOperations';

// --- External components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// --- Component
import PhoneNumberInput from 'visual/components/_/form/PhoneNumberInput';

// --- Style
const styles = theme => ({
	form: {},
});


const formRules = Object.freeze({
	email: [
		value => !!value || { i18nKey: TranslationKey.validation_email_empty },
		value => isValidEmailAddress(value) || { i18nKey: TranslationKey.validation_email_invalid },
	],
	fullname: [
		value => !!value || { i18nKey: TranslationKey.validation_fullname_empty },
	],
	// username: [
	// 	value => !!value || { i18nKey: TranslationKey.validation_username_empty },
	// ],
	password: [
		value => !!value || { i18nKey: TranslationKey.validation_password_empty },
		value => value.length >= 4 || { i18nKey: TranslationKey.validation_string_too_short, values: { min_length: 4 } },
	],
	phone: [
		value => (isStringNullOrEmpty(value) || isValidPhoneNumber(value)) || { i18nKey: TranslationKey.validation_phone_invalid },
	],
});

const defaultState = Object.freeze({
	email: '',
	fullname: '',
	username: '',
	password: '',
	phone: '',
	errors: {
		email: [],
		fullname: [],
		// username: [],
		password: [],
		phone: [],
	},
	requestError: null,
	isFormDisabled: false,
});

class Register extends React.Component {
	constructor(props) {
		super(props);

		this.state = { ...defaultState };

		this.onSubmit = this.onSubmit.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.switchToSignIn = this.switchToSignIn.bind(this);
	}

	async validateForm() {
		let hasErrors = false;

		await this.setState((currentState) => {
			const errors = Object.keys(formRules).reduce((result, field) => {
				result[field] = formRules[field]
					.map(rule => rule(currentState[field]))
					.filter(ruleResult => ruleResult !== true);

				if (result[field].length > 0)
					hasErrors = true;

				return result;
			}, {});

			console.log('Form errors', errors);

			return { errors };
		});

		return hasErrors;
	}

	async onSubmit(event) {
		if (event != null)
			event.preventDefault();

		await this.setState(currentState => ({
			requestError: null,
			isFormDisabled: true,
			email: currentState.email.toLowerCase(),
		}));

		const {
			props: {
				onSignedIn,
				getPreferencesForNewUser,
			},
			state: {
				email,
				fullname,
				username,
				password,
				phone,
			}
		} = this;

		const hasErrors = await this.validateForm();

		// Invalid fields.
		if (hasErrors) {
			await this.setState({ isFormDisabled: false });
			return;
		}

		const body = {
			...getPreferencesForNewUser(),
			email,
			password,
			users: username,
			voor_en_achternaam: fullname,
		};

		if (!isStringNullOrEmpty(phone))
			body.telefoon = phone;

		const checkIfUserExistsResponse = await API.checkIfUserExists(username);

		if (checkIfUserExistsResponse.status === 200 && checkIfUserExistsResponse.data != null && checkIfUserExistsResponse.data.length > 0) {
			await this.setState({
				isFormDisabled: false,
				requestError: { i18nKey: TranslationKey.error_register_user_already_exists }
			});
			return;
		}

		const response = await API.postUser(body);
		console.log('Register response', response);

		// User creation failed.
		if (response.status !== 201) {
			await this.setState({
				isFormDisabled: false,
				requestError: { i18nKey: TranslationKey.error_register_unknown_reason }
			});
			return;
		}

		const getUserResponse = await API.getUserFromCredentials(username, password);
		console.log('Post register get user response', getUserResponse);

		// Failed to fetch user data.
		if (getUserResponse.status !== 200 || getUserResponse.data == null || getUserResponse.data.length <= 0) {
			await this.setState({
				isFormDisabled: false,
				requestError: { i18nKey: TranslationKey.error_register_unknown_reason }
			});
			return;
		}

		const rawUserData = getUserResponse.data[0];

		// // `url` not present in the response of get user by ID.
		// rawUserData.url = response.data.href;

		onSignedIn({ username, password }, rawUserData);

		this.setState({ isFormDisabled: false });
	}

	switchToSignIn() {
		const { history } = this.props;

		history.push('/sign-in');
	}

	render() {
		const {
			props: {
				t,
				classes,
			},
			state: {
				errors,
				requestError,
				isFormDisabled,
			}
		} = this;

		/*
		<Grid item>
			<TextField
				required
				fullWidth
				size="small"
				autoComplete="off"
				variant="outlined"
				disabled={isFormDisabled}
				value={this.state.username}
				label={t(TranslationKey.username)}
				error={errors.username.length > 0}
				helperText={errors.username.length > 0 ? t(errors.username[0].i18nKey, errors.username[0].values) : null}
				onChange={({ target: { value } }) => this.setState((previousState) => {
					if (errors.username.length > 0)
						return { username: value, errors: { ...previousState.errors, username: [] } };
					return { username: value };
				})}
			/>
		</Grid>

		<TextField
			fullWidth
			size="small"
			type="phone"
			variant="outlined"
			autoComplete="off"
			disabled={isFormDisabled}
			value={this.state.phone}
			label={t(TranslationKey.phone)}
			error={errors.phone.length > 0}
			// FormHelperTextProps={{ style: { textAlign: 'justify' } }}
			helperText={errors.phone.length > 0 ? t(errors.phone[0].i18nKey, errors.phone[0].values) : t(TranslationKey.help_text_enter_phone_number)}
			onChange={({ target: { value } }) => this.setState((previousState) => {
				if (errors.phone.length > 0)
					return { phone: value, errors: { ...previousState.errors, phone: [] } };
				return { phone: value };
			})}
		/>
		*/

		return (
			<Container
				disableGutters
				className="page"
			>
				<Typography
					variant="h5"
					component="h2"
					className="pageTitle"
					style={{ textAlign: 'center', marginBottom: '16px' }}
				>
					{t(TranslationKey.register)}
				</Typography>
				<Container
					noValidate
					maxWidth="xs"
					disableGutters
					component="form"
					onSubmit={this.onSubmit}
					className="pageContainer"
				>
					<Grid
						container
						spacing={2}
						justify="center"
						direction="column"
						className="content"
						alignItems="stretch"
					>
						<Grid item>
							<TextField
								required
								fullWidth
								type="email"
								size="small"
								variant="outlined"
								disabled={isFormDisabled}
								value={this.state.email}
								label={t(TranslationKey.email)}
								error={errors.email.length > 0}
								helperText={errors.email.length > 0 ? t(errors.email[0].i18nKey, errors.email[0].values) : null}
								onChange={({ target: { value } }) => this.setState((previousState) => {
									if (errors.email.length > 0)
										return { email: value, username: value, errors: { ...previousState.errors, email: [] } };
									return { email: value, username: value };
								})}
							/>
						</Grid>
						<Grid item>
							<TextField
								required
								fullWidth
								size="small"
								variant="outlined"
								disabled={isFormDisabled}
								value={this.state.fullname}
								label={t(TranslationKey.fullname)}
								error={errors.fullname.length > 0}
								helperText={errors.fullname.length > 0 ? t(errors.fullname[0].i18nKey, errors.fullname[0].values) : null}
								onChange={({ target: { value } }) => this.setState((previousState) => {
									if (errors.fullname.length > 0)
										return { fullname: value, errors: { ...previousState.errors, fullname: [] } };
									return { fullname: value };
								})}
							/>
						</Grid>
						<Grid item>
							<PhoneNumberInput
								country={'nl'}
								value={this.state.phone}
								label={t(TranslationKey.phone)}
								error={errors.phone.length > 0}
								id="register-phone-number-input-field"
								helperText={errors.phone.length > 0 ? t(errors.phone[0].i18nKey, errors.phone[0].values) : t(TranslationKey.help_text_enter_phone_number)}
								onChange={value => this.setState((previousState) => {
									if (errors.phone.length > 0)
										return { phone: value, errors: { ...previousState.errors, phone: [] } };
									return { phone: value };
								})}
							/>
						</Grid>
						<Grid item>
							<TextField
								required
								fullWidth
								size="small"
								type="password"
								id="new-password"
								variant="outlined"
								disabled={isFormDisabled}
								autoComplete="new-password"
								value={this.state.password}
								label={t(TranslationKey.password)}
								error={errors.password.length > 0}
								helperText={errors.password.length > 0 ? t(errors.password[0].i18nKey, errors.password[0].values) : null}
								onChange={({ target: { value } }) => this.setState((previousState) => {
									if (errors.password.length > 0)
										return { password: value, errors: { ...previousState.errors, password: [] } };
									return { password: value };
								})}
							/>
						</Grid>
						{requestError != null ? (
							<Grid item>
								<Typography variant="caption" color="error">
									{t(requestError.i18nKey, requestError.values)}
								</Typography>
							</Grid>
						) : null}
						<Grid item>
							<Button
								fullWidth
								size="large"
								type="submit"
								color="primary"
								disableElevation
								variant="contained"
								disabled={isFormDisabled}
							>
								{t(TranslationKey.register)}
							</Button>
						</Grid>
						<Grid item>
							<Button
								fullWidth
								size="small"
								variant="text"
								color="primary"
								disabled={isFormDisabled}
								onClick={this.switchToSignIn}
							>
								{t(TranslationKey.sign_in)}
							</Button>
						</Grid>
					</Grid>
				</Container>
			</Container>
		);
	}
}

Register.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,
	history: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	onSignedIn: PropTypes.func.isRequired,
	getPreferencesForNewUser: PropTypes.func.isRequired,
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(Register);
