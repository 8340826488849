const Color = Object.freeze({
	Transparent: '#00000000',
	Red: '#c30000',
	DarkRed: '#900000',
	Orange: '#E87D13',
	Green: '#44932D',
	Dark16: '#161616',
	Grey32: '#323232',
	Grey80: '#808080',
});

export default Color;
