import Color from 'logic/enums/Color';


const HouseStatusColor = Object.freeze({
	// PROSPECT: Color.Green, // prospect
	BESCHIKBAAR: Color.Green, // available
	VERKOCHT: Color.DarkRed, // sold
	GEANNULEERD: Color.Dark16, // cancelled
	INGETROKKEN: Color.Dark16, // withdrawn
	VERKOCHT_BIJ_INSCHRIJVING: Color.DarkRed, // sold at registration
	GEVEILD: Color.Orange, // auctioned
	VERHUURD: Color.Orange, // rented out
	ONDER_BOD: Color.Green,
	ONDER_OPTIE: Color.Green,
	IN_AANMELDING: Color.Green, // in registration
	VERHUURD_ONDER_VOORBEHOUD: Color.Orange, // rented out
	VERKOCHT_ONDER_VOORBEHOUD: Color.Orange, // sold (may change)
});

export default HouseStatusColor;
