// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { makeStyles } from '@material-ui/core/styles';

// --- Style
import './ItemHeader.sass';

const useStyles = makeStyles(theme => ({
	price: {
		fontWeight: 600,
		padding: 0,
		margin: '0 8px',
		color: theme.palette.text.primary,
	},
}));


const ItemHeader = (props) => {
	const {
		icon,
		leftTitle,
		leftSubtitle,
		rightTitle
	} = props;

	const classes = useStyles(props);

	return (
		<div className="itemHeader">
			<div className="iconContainer">
				{icon}
			</div>
			<div className="leftContainer">
				<h2 className="title">
					{leftTitle}
					{/*
					{' '}
					<span className="subtitle">
						{leftSubtitle}
					</span>
					*/}
				</h2>
				<h4 className="subtitle">
					{leftSubtitle}
				</h4>
			</div>
			<div className="rightContainer">
				<h3 className="title">
					{rightTitle}
				</h3>
			</div>
		</div>
	);
};

ItemHeader.propTypes = {
	leftTitle: PropTypes.string,
	leftSubtitle: PropTypes.string,
	rightTitle: PropTypes.string,
	icon: PropTypes.any,
};

export default ItemHeader;
