// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { makeStyles } from '@material-ui/core/styles';

// --- Style
const useStyles = makeStyles(theme => ({
	root: {
		overflow: 'visible',
		alignItems: 'center',
		verticalAlign: 'middle',
		justifyContent: 'center',
		height: props => props.containerSize,
		width: props => props.containerSize,
		display: props => props.inline ? 'inline-flex' : 'flex',
	},
	dot: {
		width: props => props.size,
		height: props => props.size,
		background: props => props.color,
		borderRadius: props => props.size / 2,
	},
}));


const Dot = ({
	size,
	color,
	inline,
	containerSize,
	...props
}) => {
	const classes = useStyles({
		size,
		color,
		inline,
		containerSize
	});

	return (
		<div className={classes.root} {...props}>
			<div className={classes.dot} />
		</div>
	);
};

Dot.propTypes = {
	size: PropTypes.number,
	inline: PropTypes.bool,
	color: PropTypes.string,
	containerSize: PropTypes.number,
};

Dot.defaultProps = {
	size: 8,
	inline: false,
	color: '#808080',
	containerSize: 24,
};

export default Dot;
