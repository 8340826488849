import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const defaultConfiguration = {
	lng: 'en',
	fallbackLng: 'en',

	keySeparator: false,

	interpolation: {
		escapeValue: false
	}
};

// passes i18n down to react-i18next
i18n.use(initReactI18next);

export default i18n;
