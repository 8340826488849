// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';

// --- External tools
import { makeStyles } from '@material-ui/core/styles';

// --- External components
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(theme => ({
	root: {
		minHeight: props => `${props.collapsedHeight + 38}px`,
	},
	container: {
		display: 'flex',
		position: 'relative',
	},
	gradient: {
		position: 'absolute',
		top: '60%',
		bottom: 0,
		width: '100%',
		display: props => props.shown ? 'none' : 'unset',
		background: `linear-gradient(to top, ${theme.palette.background.default}, ${theme.palette.background.default}00)`,
	},
	button: {
		color: theme.palette.primary.main,
	}
}));

function ShowMore(props) {
	const {
		t,
		children,
		collapsedHeight,
		buttonShowTextTKey,
		buttonHideTextTKey,
		...other
	} = props;

	const [shown, setChecked] = React.useState(false);

	const classes = useStyles({ shown, ...props });

	const handleChange = () => {
		setChecked(prev => !prev);
	};

	return (
		<div
			{...other}
			className={classes.root}
		>
			<div className={classes.container}>
				<Collapse
					in={shown}
					collapsedHeight={collapsedHeight}
				>
					{children}
				</Collapse>
				<div className={classes.gradient}/>
			</div>
			<Button
				onClick={handleChange}
				className={classes.button}
			>
				{t(shown ? buttonHideTextTKey : buttonShowTextTKey)}
			</Button>
		</div>
	);
}

ShowMore.propTypes = {
	t: PropTypes.func.isRequired,
	collapsedHeight: PropTypes.number,
	children: PropTypes.any.isRequired,
	buttonShowTextTKey: PropTypes.string,
	buttonHideTextTKey: PropTypes.string,
};

ShowMore.defaultProps = {
	collapsedHeight: 80,
	buttonShowTextTKey: TranslationKey.show_more,
	buttonHideTextTKey: TranslationKey.show_less,
};

export default ShowMore;
