// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';

// --- Logic
import i18n from 'logic/translation/i18n';
import StorageKey from 'logic/enums/StorageKey';
import TranslationKey from 'logic/enums/TranslationKey';
import AuthenticationStatus from 'logic/enums/AuthenticationStatus';

// --- External components
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

// --- Components
import EditAccountModal from 'visual/components/modals/EditAccountModal';
import ApplyFiltersModal from 'visual/components/modals/ApplyFiltersModal';
import ConfirmationModal from 'visual/components/modals/ConfirmationModal';
import DeleteAccountModal from 'visual/components/modals/DeleteAccountModal';
import ChangePasswordModal from 'visual/components/modals/ChangePasswordModal';

// --- Style
const styles = theme => ({
	deleteAccountButton: {
		color: theme.palette.text.inDanger,
		background: theme.palette.background.danger,
		'&:hover': {
			background: theme.palette.background.danger,
		},
	},
});


class Preferences extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isEditAccountModalOpen: false,
			isDeleteAccountModalOpen: false,
			isChangePasswordModalOpen: false,
			isFilterPreferenceModalOpen: false,
			isSignOutConfirmationModalOpen: false,
		};

		this.signOut = this.signOut.bind(this);
		this.onThemeSelected = this.onThemeSelected.bind(this);
		this.onLanguageSelected = this.onLanguageSelected.bind(this);
		this.onPushNotificationSelected = this.onPushNotificationSelected.bind(this);
		this.onEmailNotificationSelected = this.onEmailNotificationSelected.bind(this);
	}


	// --- Framework methods
	render() {
		const {
			props: {
				t,
				theme,
				classes,
				userData,
				onSignedOut,
				onAccountEdited,
				supportedThemes,
				supportedCities,
				booleanSelectors,
				filterPreference,
				supportedLanguages,
				supportedHouseStatus,
				authenticationStatus,
				onCredentialsChanged,
				updateHouseListFilters,
				supportedPropertyTypes,
			},
		} = this;

		const isAuthenticated = authenticationStatus === AuthenticationStatus.Authenticated;

		let notSignedIn = null;
		let signedInElements = null;
		let accountInfo = null;
		let signOutButton = null;
		let editAccountButton = null;
		let changePasswordButton = null;
		let deleteAccountButton = null;
		if (isAuthenticated) {
			if (userData != null) {
				accountInfo = (
					<Grid item xs={12}>
						<Typography variant="subtitle2">
							<Trans
								values={{ username: userData.voor_en_achternaam.split(' ')[0] }}
								i18nKey={TranslationKey.connected_as_username}
								default="Connected as <strong>{{username}}</strong>."
							/>
						</Typography>
					</Grid>
				);
			}

			signOutButton = (
				<Grid
					item
					xs={12}
					sm={6}
				>
					<Button
						fullWidth
						size="small"
						color="primary"
						disableElevation
						variant="contained"
						onClick={() => this.setState({ isSignOutConfirmationModalOpen: true })}
					>
						{t(TranslationKey.sign_out)}
					</Button>
					<ConfirmationModal
						onCompleted={this.signOut}
						cancelText={t(TranslationKey.cancel)}
						confirmText={t(TranslationKey.sign_out)}
						contentText={t(TranslationKey.modal_confirm_sign_out)}
						isOpen={this.state.isSignOutConfirmationModalOpen}
						onClose={() => this.setState({ isSignOutConfirmationModalOpen: false })}
					/>
				</Grid>
			);

			editAccountButton = (
				<Grid
					item
					xs={12}
					sm={6}
				>
					<Button
						fullWidth
						size="small"
						color="primary"
						disableElevation
						variant="contained"
						onClick={() => this.setState({ isEditAccountModalOpen: true })}
					>
						{t(TranslationKey.update_profile)}
					</Button>
					<EditAccountModal
						userData={userData}
						onCompleted={onAccountEdited}
						onFailedToComplete={onSignedOut}
						isOpen={this.state.isEditAccountModalOpen}
						onClose={() => this.setState({ isEditAccountModalOpen: false })}
					/>
				</Grid>
			);

			changePasswordButton = (
				<Grid
					item
					xs={12}
					sm={6}
				>
					<Button
						fullWidth
						size="small"
						color="primary"
						disableElevation
						variant="contained"
						onClick={() => this.setState({ isChangePasswordModalOpen: true })}
					>
						{t(TranslationKey.change_password)}
					</Button>
					<ChangePasswordModal
						userData={userData}
						isOpen={this.state.isChangePasswordModalOpen}
						onClose={() => this.setState({ isChangePasswordModalOpen: false })}
						onCompleted={newPassword => onCredentialsChanged(userData.users, newPassword)}
					/>
				</Grid>
			);

			deleteAccountButton = (
				<Grid
					item
					xs={12}
					sm={6}
				>
					<Button
						fullWidth
						size="small"
						disableElevation
						variant="contained"
						className={classes.deleteAccountButton}
						onClick={() => this.setState({ isDeleteAccountModalOpen: true })}
					>
						{t(TranslationKey.delete_account)}
					</Button>
					<DeleteAccountModal
						userData={userData}
						onCompleted={this.signOut}
						isOpen={this.state.isDeleteAccountModalOpen}
						onClose={() => this.setState({ isDeleteAccountModalOpen: false })}
					/>
				</Grid>
			);

			signedInElements = (
				<Grid
					container
					spacing={1}
					wrap="wrap"
					style={{ marginBottom: 32 }}
				>
					{accountInfo}
					{signOutButton}
					{editAccountButton}
					{changePasswordButton}
					{deleteAccountButton}
				</Grid>
			);
		} else {
			notSignedIn = (
				<Grid
					item
					xs={12}
					sm="auto"
					style={{ margin: 'auto' }}
				>
					<Alert severity="warning">
						<Trans i18nKey={TranslationKey.page_preferences_caveat_not_signed_in}/>
					</Alert>
				</Grid>
			);
		}

		let languageSelector = null;
		if (supportedLanguages != null) {
			languageSelector = (
				<Grid item>
					<FormControl
						fullWidth
						size="small"
						variant="outlined"
					>
						<InputLabel id="select-preferred-language-label">
							{t(TranslationKey.select_language)}
						</InputLabel>
						<Select
							value={i18n.language}
							id="select-preferred-language"
							onChange={this.onLanguageSelected}
							label={t(TranslationKey.select_language)}
							labelId="select-preferred-language-label"
						>
							{supportedLanguages.map(({ id, label }) => (
								<MenuItem
									key={id}
									value={label}
								>
									{t(label)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			);
		}

		let themeSelector = null;
		if (supportedThemes != null) {
			themeSelector = (
				<Grid item>
					<FormControl
						fullWidth
						size="small"
						variant="outlined"
					>
						<InputLabel id="select-preferred-theme-label">
							{t(TranslationKey.select_theme)}
						</InputLabel>
						<Select
							value={theme}
							id="select-preferred-language"
							onChange={this.onThemeSelected}
							label={t(TranslationKey.select_theme)}
							style={{ textTransform: 'capitalize' }}
							labelId="select-preferred-theme-label"
						>
							{supportedThemes.map(({ id, label }) => (
								<MenuItem
									key={id}
									value={label}
									style={{ textTransform: 'capitalize' }}
								>
									{t(TranslationKey[label])}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			);
		}

		let booleanSelectorMenuItems = null;
		if (booleanSelectors != null) {
			booleanSelectorMenuItems = Object.keys(booleanSelectors).map(key => (
				<MenuItem
					key={booleanSelectors[key].id}
					value={booleanSelectors[key].id}
					style={{ textTransform: 'capitalize' }}
				>
					{booleanSelectors[key][i18n.language]}
				</MenuItem>
			));
		}

		let emailNotificationsSelector = null;
		// let pushNotificationsSelector = null;
		if (booleanSelectorMenuItems != null) {
			emailNotificationsSelector = (
				<Grid item>
					<FormControl
						fullWidth
						size="small"
						variant="outlined"
					>
						<InputLabel id="select-preferred-email-notifications-label">
							{t(TranslationKey.select_email_notifications)}
						</InputLabel>
						<Select
							disabled={!isAuthenticated}
							id="select-preferred-language"
							style={{ textTransform: 'capitalize' }}
							onChange={this.onEmailNotificationSelected}
							labelId="select-preferred-email-notifications-label"
							label={t(TranslationKey.select_email_notifications)}
							value={userData != null ? userData.emailNotifications : booleanSelectors.nee.id}
						>
							{booleanSelectorMenuItems}
						</Select>
					</FormControl>
				</Grid>
			);

			// pushNotificationsSelector = (
			// 	<Grid item>
			// 		<FormControl
			// 			fullWidth
			// 			size="small"
			// 			variant="outlined"
			// 		>
			// 			<InputLabel id="select-preferred-push-notifications-label">
			// 				{t(TranslationKey.select_push_notifications)}
			// 			</InputLabel>
			// 			<Select
			// 				disabled={!isAuthenticated}
			// 				id="select-preferred-language"
			// 				style={{ textTransform: 'capitalize' }}
			// 				onChange={this.onPushNotificationSelected}
			// 				label={t(TranslationKey.select_push_notifications)}
			// 				labelId="select-preferred-push-notifications-label"
			// 				value={userData != null ? userData.pushNotifications : booleanSelectors.nee.id}
			// 			>
			// 				{booleanSelectorMenuItems}
			// 			</Select>
			// 		</FormControl>
			// 	</Grid>
			// );
		}

		let filterPreferenceEditor = null;
		if (supportedCities != null && supportedHouseStatus != null && supportedPropertyTypes != null) {
			filterPreferenceEditor = (
				<Grid item>
					<Button
						fullWidth
						disableElevation
						variant="outlined"
						disabled={!isAuthenticated}
						onClick={() => this.setState({ isFilterPreferenceModalOpen: true })}
					>
						{t(TranslationKey.edit_filter_preference_button)}
					</Button>
					<ApplyFiltersModal
						applyOnClear={false}
						alwaysUpdateFilterPreference
						onApply={updateHouseListFilters}
						activeFilters={filterPreference}
						isAuthenticated={isAuthenticated}
						supportedCities={supportedCities}
						filterPreference={filterPreference}
						supportedHouseStatus={supportedHouseStatus}
						isOpen={this.state.isFilterPreferenceModalOpen}
						supportedPropertyTypes={supportedPropertyTypes}
						onClose={() => this.setState({ isFilterPreferenceModalOpen: false })}
					/>
				</Grid>
			);
		}

		return (
			<Container
				disableGutters
				className="page"
			>
				<Typography
					variant="h5"
					component="h2"
					className="pageTitle"
				>
					{t(TranslationKey.page_preferences_title)}
				</Typography>
				<Container
					maxWidth="xs"
					disableGutters
					className="pageContainer"
				>
					{signedInElements}
					<Grid
						container
						spacing={2}
						direction="column"
					>
						{languageSelector}
						{themeSelector}
						{emailNotificationsSelector}
						{/* {pushNotificationsSelector} */}
						{filterPreferenceEditor}
						{notSignedIn}
					</Grid>
				</Container>
			</Container>
		);
	}


	// --- Working methods
	signOut() {
		sessionStorage.removeItem(StorageKey.UserCredentials);
		this.props.onSignedOut();
	}


	// --- Event methods
	onLanguageSelected(event) {
		const { target: { value: selectedLanguage } } = event;

		const {
			switchLanguage,
			supportedLanguages,
		} = this.props;

		const languageToApply = supportedLanguages.find(({ label }) => label === selectedLanguage);

		if (languageToApply == null) {
			console.error(`The selected language ${selectedLanguage} is out of range, aborting.`);
			return;
		}

		switchLanguage(languageToApply);
	}

	onThemeSelected(event) {
		const { target: { value: selectedTheme } } = event;

		const {
			switchTheme,
			supportedThemes,
		} = this.props;

		const themeToApply = supportedThemes.find(({ label }) => label === selectedTheme);

		if (themeToApply == null) {
			console.error(`The selected theme ${selectedTheme} is out of range, aborting.`);
			return;
		}

		switchTheme(themeToApply);
	}

	onEmailNotificationSelected(event) {
		const { target: { value: booleanID } } = event;

		const { switchEmailNotifications } = this.props;

		switchEmailNotifications(booleanID);
	}

	onPushNotificationSelected(event) {
		const { target: { value: booleanID } } = event;

		const { switchPushNotifications } = this.props;

		switchPushNotifications(booleanID);
	}
}

Preferences.propTypes = {
	userData: PropTypes.object,
	onSignedOut: PropTypes.func,
	switchTheme: PropTypes.func,
	t: PropTypes.func.isRequired,
	switchLanguage: PropTypes.func,
	onAccountEdited: PropTypes.func,
	filterPreference: PropTypes.object,
	theme: PropTypes.string.isRequired,
	booleanSelectors: PropTypes.object,
	onCredentialsChanged: PropTypes.func,
	updateHouseListFilters: PropTypes.func,
	switchPushNotifications: PropTypes.func,
	switchEmailNotifications: PropTypes.func,
	supportedCities: PropTypes.object.isRequired,
	supportedHouseStatus: PropTypes.object.isRequired,
	supportedPropertyTypes: PropTypes.object.isRequired,
	supportedThemes: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
	})),
	supportedLanguages: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
	})),
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	authenticationStatus: PropTypes.oneOf(Object.values(AuthenticationStatus)).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

Preferences.defaultProps = {
	filterPreference: null,
	switchTheme: () => console.warn('switchTheme method not provided.'),
	onSignedOut: () => console.warn('onSignedOut event method not provided.'),
	switchLanguage: () => console.warn('switchLanguage method not provided.'),
	onAccountEdited: rawUserData => console.warn('onAccountEdited method not provided.'),
	updateHouseListFilters: () => console.warn('updateHouseListFilters method not provided.'),
	switchPushNotifications: () => console.warn('switchPushNotifications method not provided.'),
	switchEmailNotifications: () => console.warn('switchEmailNotifications method not provided.'),
	onCredentialsChanged: (username, password) => console.warn('onCredentialsChanged method not provided.'),
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(Preferences);
