// --- Framework
import React from 'react';
import PropTypes from 'prop-types';


const fallbackImage = '/images/image-placeholder.png';

const Picture = ({ image, alt }) => (
	<picture className="media" title={alt}>
		<source srcSet={image}/>
		<source srcSet={fallbackImage}/>
		<img className="image" src={image} alt={alt}/>
	</picture>
);

Picture.propTypes = {
	image: PropTypes.any,
	alt: PropTypes.string,
};

export default Picture;
