// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';
import SupportedSorting from 'logic/enums/SupportedSorting';
import { isStringNullOrEmpty } from 'logic/stringOperations';
import { countActiveFilters } from 'visual/components/modals/ApplyFiltersModal';

// --- External components
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// --- Components
import { Transition } from 'App';
import SortMenu from 'visual/components/Menus/SortMenu';
import HouseCard from 'visual/components/cards/HouseCard';
import EndOfListCard from 'visual/components/cards/EndOfListCard';
import HouseInspector from 'visual/components/modals/HouseInspector';
import VirtualizedList from 'visual/components/_/list/VirtualizedList';

// --- Style
const styles = theme => ({
	openFilterModalButton: {
		borderRadius: 16,
	}
});


class Houses extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedSorting: SupportedSorting.sort_newest.id,
			supportedSorting: SupportedSorting,
		};

		this.onSort = this.onSort.bind(this);
		this.onClearSort = this.onClearSort.bind(this);
	}

	render() {
		const {
			props: {
				t,
				i18n,
				classes,
				history,
				houseList,
				contactData,
				adCategories,
				toggleBookmark,
				houseListFilters,
				filteredHouseList,
				bookmarkDictionary,
				supportedHouseStatus,
				clearHouseListFilters,
				toggleHouseListFilters,
				supportedPropertyTypes,
				houseListFiltersEnabled,
				openHouseListFilterModal,
				isLoadingFilteredHouseList,
				match: { params: { id } },
			},
			state: {
				selectedSorting,
				supportedSorting,
			}
		} = this;

		const activeFilterCount = houseListFilters != null ? countActiveFilters(houseListFilters) : 0;

		const isFilteredList = activeFilterCount > 0 && houseListFiltersEnabled;

		let housesToDisplay = isFilteredList ? filteredHouseList : houseList;

		if (!isStringNullOrEmpty(selectedSorting) && supportedSorting[selectedSorting].sort != null)
			housesToDisplay = housesToDisplay.slice().sort((a, b) => supportedSorting[selectedSorting].sort(a, b));

		let virtualizedList = null;
		let endOfList = null;

		if (isFilteredList && isLoadingFilteredHouseList)
			virtualizedList = <CircularProgress size={56} style={{ margin: '23px auto' }}/>;
		else {
			virtualizedList = (
				<VirtualizedList
					preloadingOffset={2}
					itemCount={housesToDisplay.length}
					windowHeight={window.innerHeight - 72 - 56 - 48 - 8}
					computeSizes={(rowWidth) => {
						const maxItemWidth = 416;

						// maxItemWidth is multiplied by 1.1 (+10%) to prefer switching
						// item size when the available space gets 10% larger than the minimum.
						const howManyItemsCanFit = Math.max(Math.ceil(rowWidth / (maxItemWidth * 1.1)), 1);

						// Determines by how much the item(s) should be down-scaled to fit one more
						// and fill up as much horizontal space as possible. If the row width is
						// smaller than two items but still larger than one then a horizontal gap
						// can appear around the item, which is a waste of usable space.
						//
						// Note that this only applies when the row width is larger than the max item width,
						// otherwise there can only be one item per row that takes up all the width anyway,
						// and it shouldn't be applied when the ratio turns out to be larger than 1,
						// otherwise it would mean scaling each item up beyond their maximum width,
						// which would create a gap between each item horizontally and vertically.
						let freeSpaceRatio = (rowWidth / maxItemWidth) / howManyItemsCanFit;

						// Prevents each item to be considered to take up more space than they actually can,
						// otherwise a large gab can open up between items and re-introduce a waste of space.
						if (freeSpaceRatio > 1)
							freeSpaceRatio = 1;

						const itemWidth = rowWidth < maxItemWidth ? rowWidth : maxItemWidth * freeSpaceRatio;

						// (picture with 16:9 ratio) + (footer with street name and info) + (top and bottom padding).
						const rowHeight = (itemWidth * 0.5625) + 86 + 12;

						return {
							itemWidth, // 416
							rowHeight, // 317 + 16
						};
					}}
					renderItem={(key, index, style) => (
						<div
							key={key}
							style={{
								padding: '6px',
								...style,
							}}
						>
							<HouseCard
								i18n={i18n}
								house={housesToDisplay[index]}
								to={`/houses/${housesToDisplay[index].id}`}
								toggleBookmark={toggleBookmark}
								supportedHouseStatus={supportedHouseStatus}
								bookmarked={bookmarkDictionary[housesToDisplay[index].id] != null}
							/>
						</div>
					)}
				/>
			);

			endOfList = (
				<Grid
					item
					xs={12}
				>
					<EndOfListCard
						itemsCount={housesToDisplay.length}
						isFilteredList={isFilteredList}
						translationKey={TranslationKey.houses_end_of_list}
						translationKeyWhenEmpty={TranslationKey.houses_end_of_list_empty}
						translationKeyWhenFiltered={TranslationKey.houses_end_of_list_filtered}
						displayAsAlert={housesToDisplay.length <= 0 && !isFilteredList ? 'warning' : null}
					/>
				</Grid>
			);
		}

		return (
			<Container
				disableGutters
				className="page"
			>
				<Dialog
					fullScreen
					open={id != null}
					TransitionComponent={Transition}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<HouseInspector
						t={t}
						id={id}
						i18n={i18n}
						houseList={houseList}
						contactData={contactData}
						adCategories={adCategories}
						toggleBookmark={toggleBookmark}
						onClose={() => history.push('/houses')}
						bookmarked={bookmarkDictionary[id] != null}
						supportedHouseStatus={supportedHouseStatus}
						supportedPropertyTypes={supportedPropertyTypes}
					/>
				</Dialog>
				<Grid container spacing={2}>
					<Grid item>
						<Typography
							variant="h5"
							component="h1"
							style={{ textTransform: 'capitalize' }}
						>
							{t(TranslationKey.page_explorer_title)}
						</Typography>
					</Grid>
					<Grid
						item
						container
						spacing={1}
						direction="row"
					>
						<Grid item>
							<Chip
								clickable
								color="primary"
								onClick={openHouseListFilterModal}
								style={{ textTransform: 'capitalize', fontWeight: 700 }}
								label={!isFilteredList ? t(TranslationKey.button_filter_list) : t(TranslationKey.button_filter_list_active)}
								variant={!isFilteredList ? 'outlined' : null}
								icon={!isFilteredList ? <Icon>filter_list</Icon> : null}
								onDelete={isFilteredList ? clearHouseListFilters : null}
								// deleteIcon={houseListFiltersEnabled ? <Icon>toggle_on</Icon> : <Icon>toggle_off</Icon>}
								avatar={isFilteredList ? <Avatar style={{ fontWeight: 400 }}>{activeFilterCount}</Avatar> : null}
							/>
						</Grid>
						<Grid item>
							<SortMenu
								onSelect={this.onSort}
								onClear={this.onClearSort}
								selectedSorting={selectedSorting}
								supportedSorting={supportedSorting}
							/>
						</Grid>
					</Grid>
					{virtualizedList}
					{endOfList}
				</Grid>
			</Container>
		);
	}


	// --- Event methods
	onSort(id) {
		this.setState({ selectedSorting: id });
	}

	onClearSort() {
		this.setState({ selectedSorting: '' });
	}
}

Houses.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,
	match: PropTypes.object,
	history: PropTypes.object,
	userData: PropTypes.object,
	contactData: PropTypes.object,
	adCategories: PropTypes.object,
	toggleBookmark: PropTypes.func,
	houseListFilters: PropTypes.object,
	// activeFilterCount: PropTypes.number,
	bookmarkDictionary: PropTypes.object,
	classes: PropTypes.object.isRequired,
	clearHouseListFilters: PropTypes.func,
	toggleHouseListFilters: PropTypes.func,
	houseListFiltersEnabled: PropTypes.bool,
	openHouseListFilterModal: PropTypes.func,
	isLoadingFilteredHouseList: PropTypes.bool,
	supportedHouseStatus: PropTypes.object.isRequired,
	supportedPropertyTypes: PropTypes.object.isRequired,
	houseList: PropTypes.arrayOf(PropTypes.object).isRequired,
	filteredHouseList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Houses.defaultProps = {
	// activeFilterCount: 0,
	houseListFilters: null,
	houseListFiltersEnabled: false,
	isLoadingFilteredHouseList: false,
	toggleBookmark: () => console.warn('toggleBookmark method not provided.'),
	clearHouseListFilters: () => console.warn('clearHouseListFilters method not provided.'),
	toggleHouseListFilters: () => console.warn('toggleHouseListFilters method not provided.'),
	openHouseListFilterModal: () => console.warn('openHouseListFilterModal method not provided.'),
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(Houses);
