// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const postUser = (newUserData, contextConfiguration) => axios.post(
	'/users',
	newUserData,
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default postUser;
