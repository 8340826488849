export const { parse } = JSON;

// See https://stackoverflow.com/a/3710226
export const parseOrNull = (str) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return null;
	}
};
