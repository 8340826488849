// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const patchUser = (userID, changes, contextConfiguration) => axios.patch(
	`/users/${userID}`,
	changes,
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default patchUser;
