// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


// The themes is currently saved using a boolean table "yes_no",
// "ja" or "yes" meaning dark mode, "nee" or "no" meaning light mode.
const getSupportedThemes = contextConfiguration => axios.get(
	'/yes_no',
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default getSupportedThemes;
