// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


// publicatiedatum koopprijs
const getHouses = (queryParameters, contextConfiguration) => {
	let url = '/wonen?offline=_NOT_IN_(I20210628101211630417)&fields=*';

	if (queryParameters != null && queryParameters.length > 0) {
		// url += '?';

		queryParameters.forEach(({ queryParameter, value }, index) => {
			// if (index > 0)
			// 	url += '&';

			url += `&${encodeURI(queryParameter)}=${encodeURI(value)}`;
		});
	}

	return axios.get(
		url,
		{
			...commonConfiguration,
			...contextConfiguration,
		}
	).catch(onRequestError);
};

export default getHouses;
