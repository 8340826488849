// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

// --- IO
import API from 'io/API';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';
import { getErrorMessageFromResponse } from 'logic/requestOperations';

// --- External components
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';

// --- Components
import { Transition } from 'App';
import { isStringNullOrEmpty } from '../../../logic/stringOperations';

// --- Style
const styles = theme => ({});


const formRules = Object.freeze({
	password: [
		value => !!value || { i18nKey: TranslationKey.validation_password_empty },
	],
	newPassword: [
		value => !!value || { i18nKey: TranslationKey.validation_password_empty },
	],
});

const defaultState = Object.freeze({
	password: '',
	newPassword: '',
	errors: {
		password: [],
		newPassword: [],
	},
	requestError: null,
	isFormDisabled: false,
});

class ChangePasswordModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = { ...defaultState };

		this.onSubmit = this.onSubmit.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.validateForm = this.validateForm.bind(this);
	}

	async validateForm() {
		await this.setState({ isFormDisabled: true });

		let hasErrors = false;

		await this.setState((currentState) => {
			const errors = Object.keys(formRules).reduce((result, field) => {
				result[field] = formRules[field]
					.map(rule => rule(currentState[field]))
					.filter(ruleResult => ruleResult !== true);

				if (result[field].length > 0)
					hasErrors = true;

				return result;
			}, {});

			console.log('Form errors', errors);

			return {
				errors,
				isFormDisabled: false
			};
		});

		return hasErrors;
	}

	async onSubmit(event) {
		if (event != null)
			event.preventDefault();

		await this.setState({ requestError: null });

		const {
			props: {
				userData,
				onCompleted,
			},
			state: {
				password,
				newPassword,
			}
		} = this;

		const hasErrors = await this.validateForm();

		// Invalid field(s).
		if (hasErrors)
			return;

		const getResponse = await API.getUserFromCredentials(userData.users, password, null);
		console.log('Get user response', getResponse);

		const getErrorMessage = getErrorMessageFromResponse(getResponse, 200);
		if (getErrorMessage != null) {
			await this.setState({ requestError: getErrorMessage });
			return;
		}

		// No user found, wrong password.
		if (getResponse.data.length <= 0) {
			await this.setState({ requestError: { i18nKey: TranslationKey.error_wrong_password } });
			return;
		}

		let id;
		if (!isStringNullOrEmpty(getResponse.data[0].url)) {
			const splitUrl = getResponse.data[0].url.split('/');
			id = splitUrl[splitUrl.length - 1];
		}

		// Different user found, wrong password.
		if (isStringNullOrEmpty(id) || id !== userData.id) {
			await this.setState({ requestError: { i18nKey: TranslationKey.error_wrong_password } });
			return;
		}

		const patchResponse = await API.patchUser(userData.id, { password: newPassword });
		console.log('Patch user response', patchResponse);

		const patchErrorMessage = getErrorMessageFromResponse(patchResponse, 200);
		if (patchErrorMessage != null) {
			await this.setState({ requestError: patchErrorMessage });
			return;
		}

		this.closeModal();

		onCompleted(newPassword);
	}

	closeModal() {
		const { onClose } = this.props;

		this.setState({ ...defaultState });

		onClose();
	}

	render() {
		const {
			props: {
				t,
				isOpen,
				classes,
			},
			state: {
				errors,
				requestError,
				isFormDisabled,
			}
		} = this;

		return (
			<Dialog
				fullScreen
				open={isOpen}
				TransitionComponent={Transition}
				aria-labelledby="change-password-title"
				aria-describedby="change-password-content"
			>
				<div className="modal">
					<DialogTitle
						disableTypography
						className="modalBar"
						id="change-password-title"
					>
						<Button
							onClick={this.closeModal}
							disabled={isFormDisabled}
							aria-label="close button"
							startIcon={<Icon>chevron_left</Icon>}
						>
							{t(TranslationKey.back)}
						</Button>
						<Typography
							variant="h6"
							className="modalTitle"
						>
							{t(TranslationKey.change_password)}
						</Typography>
					</DialogTitle>
					<Container
						noValidate
						maxWidth="xs"
						disableGutters
						component="form"
						onSubmit={this.onSubmit}
						className="modalBody gridPadding"
					>
						<Grid
							container
							spacing={2}
							justify="center"
							direction="column"
							alignItems="stretch"
							className="content"
						>
							<Grid item>
								<TextField
									fullWidth
									type="password"
									variant="outlined"
									disabled={isFormDisabled}
									value={this.state.password}
									error={errors.password.length > 0}
									label={t(TranslationKey.current_password)}
									helperText={errors.password.length > 0 ? t(errors.password[0].i18nKey, errors.password[0].values) : null}
									onChange={({ target: { value } }) => this.setState((previousState) => {
										if (errors.password.length > 0)
											return { password: value, errors: { ...previousState.errors, password: [] } };
										return { password: value };
									})}
								/>
							</Grid>
							<Grid item>
								<TextField
									fullWidth
									variant="outlined"
									type="new-password"
									disabled={isFormDisabled}
									value={this.state.newPassword}
									error={errors.newPassword.length > 0}
									label={t(TranslationKey.new_password)}
									helperText={errors.newPassword.length > 0 ? t(errors.newPassword[0].i18nKey, errors.newPassword[0].values) : null}
									onChange={({ target: { value } }) => this.setState((previousState) => {
										if (errors.newPassword.length > 0)
											return { newPassword: value, errors: { ...previousState.errors, newPassword: [] } };
										return { newPassword: value };
									})}
								/>
							</Grid>
							{requestError != null ? (
								<Grid item>
									<Typography variant="caption" color="error">
										{t(requestError.i18nKey, requestError.values)}
									</Typography>
								</Grid>
							) : null}
							<Grid item>
								<Button
									fullWidth
									size="large"
									type="submit"
									color="primary"
									disableElevation
									variant="contained"
									disabled={isFormDisabled}
								>
									{t(TranslationKey.change_password)}
								</Button>
							</Grid>
						</Grid>
					</Container>
					<div className="footer">
						<Button
							className="button"
							onClick={this.closeModal}
							disabled={isFormDisabled}
						>
							{t(TranslationKey.cancel)}
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}
}

ChangePasswordModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	onCompleted: PropTypes.func.isRequired,
};

ChangePasswordModal.defaultProps = {
	isOpen: false,
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(ChangePasswordModal);
