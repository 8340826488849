import ThemeKey from './ThemeKey';


const TranslationKey = Object.freeze({
	// --- Generic
	no: 'no',
	yes: 'yes',
	and: 'and',
	back: 'back',
	cancel: 'cancel',
	confirm: 'confirm',
	username: 'username',
	fullname: 'fullname',
	password: 'password',
	contact: 'contact',
	privacy_policy: 'privacy_policy',
	phone: 'phone',
	email: 'email',
	register: 'register',
	forgot_password: 'forgot_password',
	password_reset: 'password_reset',
	to_sign_in: 'to_sign_in',
	reset_link_sent: 'reset_link_sent',
	sign_in: 'sign_in',
	sign_out: 'sign_out',
	minimum: 'minimum',
	maximum: 'maximum',
	between: 'between',
	are_you_sure: 'are_you_sure',
	show_more: 'show_more',
	show_less: 'show_less',
	loading: 'loading',

	// --- Component specific
	navbar_explorer: 'navbar_explorer',
	navbar_favorites: 'navbar_favorites',
	navbar_preferences: 'navbar_preferences',
	navbar_information: 'navbar_information',
	page_explorer_title: 'page_explorer_title',
	page_favorites_title: 'page_favorites_title',
	page_favorites_caveat_not_signed_in: 'page_favorites_caveat_not_signed_in',
	page_preferences_title: 'page_preferences_title',
	page_preferences_caveat_not_signed_in: 'page_preferences_caveat_not_signed_in',
	page_information_title: 'page_information_title',
	end_of_list_default: 'end_of_list_default',
	end_of_list_filtered: 'end_of_list_filtered',
	end_of_list_empty: 'end_of_list_empty',
	houses_end_of_list: 'houses_end_of_list',
	houses_end_of_list_filtered: 'houses_end_of_list_filtered',
	houses_end_of_list_empty: 'houses_end_of_list_empty',
	bookmarks_end_of_list: 'bookmarks_end_of_list',
	bookmarks_end_of_list_filtered: 'bookmarks_end_of_list_filtered',
	bookmarks_end_of_list_empty: 'bookmarks_end_of_list_empty',
	description_caveat_empty: 'description_caveat_empty',
	no_picture_available: 'no_picture_available',
	house_not_found: 'house_not_found',
	house_plot_area: 'house_plot_area',
	house_surface_area: 'house_surface_area',
	house_bedrooms: 'house_bedrooms',
	house_floors: 'house_floors',
	house_publication_date: 'house_publication_date',
	house_type_of_property: 'house_type_of_property',
	advertising_header: 'advertising_header',
	button_sort_list: 'button_sort_list',
	button_sort_list_active: 'button_sort_list_active',
	sort_none: 'sort_none',
	sort_newest: 'sort_newest',
	sort_price_high_low: 'sort_price_high_low',
	sort_price_low_high: 'sort_price_low_high',
	button_filter_list: 'button_filter_list',
	button_filter_list_active: 'button_filter_list_active',
	modal_filters_title: 'modal_filters_title',
	modal_filters_select_price_label: 'modal_filters_select_price_label',
	modal_filters_select_price_empty: 'modal_filters_select_price_empty',
	modal_filters_select_city_label: 'modal_filters_select_city_label',
	modal_filters_select_city_field: 'modal_filters_select_city_field',
	modal_filters_select_city_empty: 'modal_filters_select_city_empty',
	modal_filters_select_status_label: 'modal_filters_select_status_label',
	modal_filters_select_status_field: 'modal_filters_select_status_field',
	modal_filters_select_status_empty: 'modal_filters_select_status_empty',
	modal_filters_select_property_types_label: 'modal_filters_select_property_types_label',
	modal_filters_select_property_types_field: 'modal_filters_select_property_types_field',
	modal_filters_select_property_types_empty: 'modal_filters_select_property_types_empty',
	modal_filters_select_minimum_bedroom_count_label: 'modal_filters_select_minimum_bedroom_count_label',
	modal_filters_select_minimum_bedroom_count_empty: 'modal_filters_select_minimum_bedroom_count_empty',
	modal_filters_update_preference_checkbox: 'modal_filters_update_preference_checkbox',
	modal_filters_restore_preference_button: 'modal_filters_restore_preference_button',
	modal_filters_clear_button: 'modal_filters_clear_button',
	modal_filters_apply_button: 'modal_filters_apply_button',
	modal_filters_apply_and_save_button: 'modal_filters_apply_and_save_button',
	modal_request_information_submit_button: 'modal_request_information_submit_button',
	modal_request_information_subject_field_label: 'modal_request_information_subject_field_label',
	modal_request_information_body_field_label: 'modal_request_information_body_field_label',
	modal_confirm_sign_out: 'modal_confirm_sign_out',
	menu_item_any: 'menu_item_any',
	connected_as_username: 'connected_as_username',
	select_language: 'select_language',
	select_theme: 'select_theme',
	select_email_notifications: 'select_email_notifications',
	select_push_notifications: 'select_push_notifications',
	edit_filter_preference_button: 'edit_filter_preference_button',
	[ThemeKey.Light]: ThemeKey.Light, // theme_light
	[ThemeKey.Dark]: ThemeKey.Dark, // theme_dark
	update_profile: 'update_profile',
	change_password: 'change_password',
	current_password: 'current_password',
	new_password: 'new_password',
	delete_account: 'delete_account',
	help_text_enter_phone_number: 'help_text_enter_phone_number',
	warning_irreversible_action: 'warning_irreversible_action',
	warning_delete_account: 'warning_delete_account',
	validation_email_empty: 'validation_email_empty',
	validation_email_invalid: 'validation_email_invalid',
	validation_password_empty: 'validation_password_empty',
	validation_fullname_empty: 'validation_fullname_empty',
	validation_username_empty: 'validation_username_empty',
	validation_phone_invalid: 'validation_phone_invalid',
	validation_string_too_short: 'validation_string_too_short',
	error_wrong_password: 'error_wrong_password',
	error_sign_in_bad_credentials: 'error_sign_in_bad_credentials',
	error_register_unknown_reason: 'error_register_unknown_reason',
	error_register_user_already_exists: 'error_register_user_already_exists',
	error_update_profile_email_already_used: 'error_update_profile_email_already_used',
	error_missing_data_in_response: 'error_missing_data_in_response',
	error_request_failed_with_status_code: 'error_request_failed_with_status_code', // values: "status"
	error_request_failed_with_error_in_response: 'error_request_failed_with_error_in_response', // values: "error"
	development_help_text_enter_password: 'development_help_text_enter_password',

	// --- Proper nouns (people, place, brand, etc.)
	whatsapp: 'whatsapp',
	facebook: 'facebook',
});

export default TranslationKey;
