// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';

// --- Logic
import TranslationKey from 'logic/enums/TranslationKey';
import AuthenticationStatus from 'logic/enums/AuthenticationStatus';

// --- External components
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// --- Components
import { Transition } from 'App';
import HouseCard from 'visual/components/cards/HouseCard';
import EndOfListCard from 'visual/components/cards/EndOfListCard';
import HouseInspector from 'visual/components/modals/HouseInspector';
import VirtualizedList from 'visual/components/_/list/VirtualizedList';

// --- Style
const styles = theme => ({});


class Bookmarks extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {
			props: {
				t,
				i18n,
				classes,
				history,
				houseList,
				contactData,
				adCategories,
				toggleBookmark,
				bookmarkDictionary,
				authenticationStatus,
				supportedHouseStatus,
				supportedPropertyTypes,
				match: { params: { id } },
			},
		} = this;

		let notSignedIn = null;
		if (authenticationStatus !== AuthenticationStatus.Authenticated) {
			notSignedIn = (
				<Grid
					item
					xs={12}
					sm="auto"
					style={{ margin: 'auto' }}
				>
					<Alert severity="warning">
						<Trans i18nKey={TranslationKey.page_favorites_caveat_not_signed_in} />
					</Alert>
				</Grid>
			);
		}

		// const isFilteredList = houseListFiltersEnabled && houseListFilterCount > 0;

		const bookmarkedHouseList = houseList.filter(house => bookmarkDictionary[house.id] != null);

		return (
			<Container
				disableGutters
				className="page"
			>
				<Dialog
					fullScreen
					open={id != null}
					TransitionComponent={Transition}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<HouseInspector
						t={t}
						id={id}
						i18n={i18n}
						houseList={houseList}
						contactData={contactData}
						adCategories={adCategories}
						toggleBookmark={toggleBookmark}
						onClose={() => history.push('/bookmarks')}
						supportedHouseStatus={supportedHouseStatus}
						bookmarked={bookmarkDictionary[id] != null}
						supportedPropertyTypes={supportedPropertyTypes}
					/>
				</Dialog>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="h5"
							component="h1"
							style={{ textTransform: 'capitalize' }}
						>
							{t(TranslationKey.page_favorites_title)}
						</Typography>
					</Grid>
					{/*
					<Grid
						item
						container
						direction="row"
						spacing={1}
					>
						<Chip
							clickable
							color="primary"
							onClick={openHouseListFilterModal}
							style={{ textTransform: 'capitalize', fontWeight: 700 }}
							label={!isFilteredList ? t(TranslationKey.button_filter_list) : t(TranslationKey.button_filter_list_active)}
							variant={!isFilteredList ? 'outlined' : null}
							icon={!isFilteredList ? <Icon>filter_list</Icon> : null}
							onDelete={isFilteredList ? clearHouseListFilters : null}
							// deleteIcon={houseListFiltersEnabled ? <Icon>toggle_on</Icon> : <Icon>toggle_off</Icon>}
							avatar={isFilteredList ? <Avatar style={{ fontWeight: 400 }}>{houseListFilterCount}</Avatar> : null}
						/>
					</Grid>
					*/}
					<VirtualizedList
						preloadingOffset={2}
						itemCount={bookmarkedHouseList.length}
						windowHeight={window.innerHeight - 72 - 56 - 48 - 8}
						computeSizes={(rowWidth) => {
							const maxItemWidth = 416;

							// maxItemWidth is multiplied by 1.1 (+10%) to prefer switching
							// item size when the available space gets 10% larger than the minimum.
							const howManyItemsCanFit = Math.max(Math.ceil(rowWidth / (maxItemWidth * 1.1)), 1);

							// Determines by how much the item(s) should be down-scaled to fit one more
							// and fill up as much horizontal space as possible. If the row width is
							// smaller than two items but still larger than one then a horizontal gap
							// can appear around the item, which is a waste of usable space.
							//
							// Note that this only applies when the row width is larger than the max item width,
							// otherwise there can only be one item per row that takes up all the width anyway,
							// and it shouldn't be applied when the ratio turns out to be larger than 1,
							// otherwise it would mean scaling each item up beyond their maximum width,
							// which would create a gap between each item horizontally and vertically.
							let freeSpaceRatio = (rowWidth / maxItemWidth) / howManyItemsCanFit;

							// Prevents each item to be considered to take up more space than they actually can,
							// otherwise a large gab can open up between items and re-introduce a waste of space.
							if (freeSpaceRatio > 1)
								freeSpaceRatio = 1;

							const itemWidth = rowWidth < maxItemWidth ? rowWidth : maxItemWidth * freeSpaceRatio;

							// (picture with 16:9 ratio) + (footer with street name and info) + (top and bottom padding).
							const rowHeight = (itemWidth * 0.5625) + 86 + 12;

							return {
								itemWidth, // 416
								rowHeight, // 317 + 16
							};
						}}
						renderItem={(key, index, style) => (
							<div
								key={key}
								style={{
									padding: '6px',
									...style,
								}}
							>
								<HouseCard
									bookmarked
									i18n={i18n}
									toggleBookmark={toggleBookmark}
									house={bookmarkedHouseList[index]}
									supportedHouseStatus={supportedHouseStatus}
									to={`/bookmarks/${bookmarkedHouseList[index].id}`}
								/>
							</div>
						)}
					/>
					<Grid
						item
						xs={12}
					>
						<EndOfListCard
							isFilteredList={false}
							itemsCount={bookmarkedHouseList.length}
							translationKey={TranslationKey.bookmarks_end_of_list}
							translationKeyWhenEmpty={TranslationKey.bookmarks_end_of_list_empty}
							// translationKeyWhenFiltered={TranslationKey.bookmarks_end_of_list_filtered}
							displayAsAlert={bookmarkedHouseList.length <= 0 ? 'info' : null}
							// displayAsAlert={bookmarkedHouseList.length <= 0 && !isFilteredList ? 'info' : null}
						/>
					</Grid>
					{notSignedIn}
				</Grid>
			</Container>
		);
	}
}

Bookmarks.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,
	match: PropTypes.object,
	history: PropTypes.object,
	userData: PropTypes.object,
	contactData: PropTypes.object,
	adCategories: PropTypes.object,
	toggleBookmark: PropTypes.func,
	// houseListFilterCount: PropTypes.number,
	bookmarkDictionary: PropTypes.object,
	classes: PropTypes.object.isRequired,
	// clearHouseListFilters: PropTypes.func,
	// toggleHouseListFilters: PropTypes.func,
	// houseListFiltersEnabled: PropTypes.bool,
	// openHouseListFilterModal: PropTypes.func,
	supportedHouseStatus: PropTypes.object.isRequired,
	supportedPropertyTypes: PropTypes.object.isRequired,
	houseList: PropTypes.arrayOf(PropTypes.object).isRequired,
	authenticationStatus: PropTypes.oneOf(Object.values(AuthenticationStatus)).isRequired,
};

Bookmarks.defaultProps = {
	// houseListFiltersEnabled: false,
	toggleBookmark: () => console.warn('toggleBookmark method not provided.'),
	// clearHouseListFilters: () => console.warn('clearHouseListFilters method not provided.'),
	// toggleHouseListFilters: () => console.warn('toggleHouseListFilters method not provided.'),
	// openHouseListFilterModal: () => console.warn('openHouseListFilterModal method not provided.'),
};

export default compose(
	withTranslation(),
	withStyles(styles),
)(Bookmarks);
