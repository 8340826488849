const ComparisonOperator = Object.freeze({
	// Not API compatible
	Never: 'never',
	// Not API compatible
	Always: 'always',

	Less: '_LT_',
	LessEqual: '_LE_',
	Equal: '_EQ_',
	NotEqual: '_NE_',
	GreaterEqual: '_GE_',
	Greater: '_GT_',

	// String only
	Same: '=',
	// String only
	NotSame: '_NOT_',
});

export default ComparisonOperator;
