// --- Framework
// eslint-disable-next-line max-classes-per-file
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import AwesomeDebouncePromise from 'awesome-debounce-promise';


class VirtualizedList extends React.Component {
	// --- Constructor
	constructor(props) {
		super(props);

		// this.onScroll = this.onScroll.bind(this);
		this.onWindowScroll = this.onWindowScroll.bind(this);
		this.onWindowResize = this.onWindowResize.bind(this);
		this.applyContainerSize = this.applyContainerSize.bind(this);

		this.applyContainerSizeDebouced = AwesomeDebouncePromise(this.applyContainerSize, 200).bind(this);

		this.containerRef = React.createRef();

		this.state = {
			width: null,
			offsetTop: null,
			scrollTop: document.documentElement.scrollTop || document.body.scrollTop,
		};
	}


	// --- Event methods
	// onScroll(event) {
	// 	this.setState({ scrollTop: event.currentTarget.scrollTop });
	// }

	onWindowScroll() {
		this.setState({ scrollTop: document.documentElement.scrollTop || document.body.scrollTop });
	}

	onWindowResize() {
		this.applyContainerSizeDebouced();
	}


	// --- Working methods
	applyContainerSize() {
		const { clientWidth, offsetTop } = this.containerRef.current;

		this.setState({
			width: clientWidth,
			offsetTop,
			scrollTop: document.documentElement.scrollTop || document.body.scrollTop
		});
		// ,
		// () => {
		// 	const itemPerRow = Math.max(1, Math.floor(this.state.width / this.props.itemWidth));
		// 	const totalListHeight = Math.ceil(this.props.itemCount / itemPerRow) * this.props.rowHeight;
		//
		// 	console.log(`Container width: ${this.state.width}`);
		// 	console.log(`Item per row: ${itemPerRow}`);
		// 	console.log(`Total list height: ${totalListHeight}`);
		// }
	}


	// --- Framework methods
	componentDidMount() {
		this.applyContainerSize();

		window.onscroll = this.onWindowScroll;
		window.onresize = this.onWindowResize;
	}

	componentWillUnmount() {
		window.onscroll -= this.onWindowScroll;
		window.onresize -= this.onWindowResize;
	}

	render() {
		const {
			props: {
				itemCount,
				renderItem,
				computeSizes,
				windowHeight,
				preloadingOffset,
			},
			state: {
				width,
				offsetTop,
				scrollTop,
			}
		} = this;

		const { rowHeight, itemWidth } = computeSizes(width);

		const scroll = Math.max(0, scrollTop - offsetTop);

		const itemPerRow = Math.max(1, Math.floor(width / itemWidth));
		const listHeight = Math.ceil(itemCount / itemPerRow) * rowHeight;

		const startIndex = Math.max(0, Math.floor(scroll / rowHeight) - preloadingOffset);

		// Prevents rendering past the end of the list.
		const endIndex = Math.min(Math.ceil(itemCount / itemPerRow) - 1, Math.floor((scrollTop + windowHeight) / rowHeight) + preloadingOffset);

		const rows = [];
		let itemIndex = startIndex * itemPerRow;
		for (let rowIndex = startIndex; rowIndex <= endIndex; rowIndex++) {
			const items = [];

			for (let i = 0; i < itemPerRow && itemIndex < itemCount; i++) {
				items.push(
					renderItem(
						`virtualized-list-item-${itemIndex}`,
						itemIndex,
						{
							height: '100%',
							width: `${itemWidth}px`
						}
					)
				);

				itemIndex++;
			}

			rows.push(
				<div
					key={`virtualized-list-row-${rowIndex}`}
					style={{
						width: '100%',
						display: 'flex',
						position: 'absolute',
						flexDirection: 'row',
						justifyContent: 'center',
						height: `${rowHeight}px`,
						top: `${rowIndex * rowHeight}px`,
					}}
				>
					{items}
				</div>
			);
		}

		return (
			<div
				ref={this.containerRef}
				className="virtualized-list"
				style={{
					width: '100%',
					position: 'relative',
					height: `${listHeight}px`,
				}}
			>
				{rows}
			</div>
		);

		/* FOR A LIST NOT BASED ON THE SCROLLING OF THE ENTIRE PAGE
		<div
			ref={this.containerRef}
			className="virtualized-list"
			style={{ overflowY: 'scroll', width: '100%', height: `${windowHeight}px`, }}
			onScroll={this.onScroll}
		>
			<div
					className="inner-container"
					style={{
						position: 'relative',
						height: `${listHeight}px`,
						width: '100%',
					}}
				>
					{rows}
				</div>
		*/
	}
}

VirtualizedList.propTypes = {
	itemCount: PropTypes.number,
	preloadingOffset: PropTypes.number,
	renderItem: PropTypes.func.isRequired,
	computeSizes: PropTypes.func.isRequired,
	windowHeight: PropTypes.number.isRequired,
};

VirtualizedList.defaultProps = {
	itemCount: 0,
	preloadingOffset: 0,
};


export default VirtualizedList;
