// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import { withStyles } from '@material-ui/core/styles';

// --- Logic
import Color from 'logic/enums/Color';
import HouseStatusColor from 'logic/enums/HouseStatusColor';
import { isStringNullOrEmpty } from 'logic/stringOperations';

// --- External components
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Icon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';

// --- Components
import Picture from 'visual/components/_/media/Picture';
import ItemStatus from 'visual/components/_/item/ItemStatus';
import ItemHeader from 'visual/components/_/item/ItemHeader';
import DetailChip from 'visual/components/_/chips/DetailChip';
import MediaContainer from 'visual/components/_/media/MediaContainer';

// --- Assets
import PlotIcon from 'assets/icons/plot.svg';
import SurfaceIcon from 'assets/icons/surface.svg';
import BedroomIcon from 'assets/icons/bedroom.svg';
import LocationIcon from 'assets/icons/location.svg';

// --- Style
import './HouseCardStyle.sass';

const styles = theme => ({
	headerIcon: {
		padding: 0,
		margin: 0,
		fontSize: 36,
		color: theme.palette.text.primary,
	},
	bookmarkButton: {
		padding: 6,
		color: theme.palette.text.bookmark,
		background: theme.palette.background.bookmark,
		position: 'absolute',
		right: 8,
		top: 8,
		'&:hover': {
			background: theme.palette.background.bookmarkActive,
		},
	},
	bookmarkButtonActive: {
		padding: 6,
		color: theme.palette.text.bookmarkActive,
		background: theme.palette.background.bookmarkActive,
		position: 'absolute',
		right: 8,
		top: 8,
		'&:hover': {
			background: theme.palette.background.bookmark,
		},
	},
});


class HouseCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	// componentDidMount() {
	// 	const {
	// 		props: {
	// 			house,
	// 			houseDetails,
	// 			getHouseDetails,
	// 		},
	// 	} = this;
	//
	// 	if (houseDetails[house.id] == null)
	// 		getHouseDetails(house.id);
	// }

	render() {
		const {
			props: {
				to,
				i18n,
				house,
				classes,
				bookmarked,
				toggleBookmark,
				supportedHouseStatus,
			},
		} = this;

		if (house == null || house === {})
			return null;

		let statusComponent = null;
		if (house.status != null && house.status.length > 0) {
			const status = house.status[0].label;

			statusComponent = (
				<ItemStatus
					color={HouseStatusColor[status] || Color.Grey32}
					label={supportedHouseStatus[status][i18n.language]}
				/>
			);
		}

		let street = '-';
		if (!isStringNullOrEmpty(house.straat)) {
			street = house.straat;

			if (!isStringNullOrEmpty(house.huisnummer))
				street += ` ${house.huisnummer}`;
		}

		const city = house.plaats != null && house.plaats.length > 0 ? house.plaats[0].label : '';

		const label = `${street}, ${city}`;

		// const price = house.koopprijs != null ? insertThousandsSeparator(house.koopprijs) : '-';

		let price = '-';
		if (house.koopprijs != null) {
			price = new Intl.NumberFormat(i18n.language, {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0,
				// notation: 'compact',
			}).format(house.koopprijs);
		}

		const detailChips = (
			<div className="details">
				<DetailChip
					icons={[
						<SvgIcon
							key="plot-icon"
							component={PlotIcon}
							style={{ fontSize: 26 }}
						/>,
					]}
					labels={[
						!isStringNullOrEmpty(house.perceeloppervlakte) ? (<span>{house.perceeloppervlakte}m<sup>2</sup></span>) : '-',
					]}
				/>
				<DetailChip
					icons={[
						<SvgIcon
							key="surface-icon"
							component={SurfaceIcon}
							style={{ fontSize: 26 }}
						/>,
					]}
					labels={[
						!isStringNullOrEmpty(house.woonoppervlakte) ? (<span>{house.woonoppervlakte}m<sup>2</sup></span>) : '-',
					]}
				/>
				<DetailChip
					icons={[
						<SvgIcon
							key="bedrooms-icon"
							component={BedroomIcon}
							style={{ fontSize: 24 }}
						/>,
					]}
					labels={[
						house.aantal_slaapkamers || '-',
					]}
				/>
			</div>
		);

		return (
			<Card className="houseCard">
				<Link to={to} className="linkInvisible">
					<MediaContainer neverWide>
						<Picture
							image={house.hoofdfoto}
							alt={label}
						/>
					</MediaContainer>
					{statusComponent}
					<ItemHeader
						icon={<SvgIcon component={LocationIcon} className={classes.headerIcon} />}
						leftTitle={street}
						leftSubtitle={city}
						rightTitle={price}
					/>
					{detailChips}
				</Link>
				{bookmarked ? (
					<IconButton
						color="primary"
						aria-label="bookmark"
						className={classes.bookmarkButtonActive}
						onClick={() => toggleBookmark(house.id)}
					>
						<Icon>grade</Icon>
					</IconButton>
				) : (
					<IconButton
						aria-label="bookmark"
						className={classes.bookmarkButton}
						onClick={() => toggleBookmark(house.id)}
					>
						<Icon>grade</Icon>
					</IconButton>
				)}
			</Card>
		);
	}
}

HouseCard.propTypes = {
	to: PropTypes.string.isRequired,
	i18n: PropTypes.object.isRequired,
	house: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	bookmarked: PropTypes.bool.isRequired,
	toggleBookmark: PropTypes.func.isRequired,
	supportedHouseStatus: PropTypes.object.isRequired,
};

export default withStyles(styles)(HouseCard);
