// --- Framework
import React from 'react';

// --- External components
import Icon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/SvgIcon';

// --- Assets
import WhatsappIcon from 'assets/icons/whatsapp.svg';


const ContactIcon = Object.freeze({
	email: <Icon>email</Icon>, // alternate_email
	whatsapp: <SvgIcon component={WhatsappIcon}/>,
	phone: <Icon>phone</Icon>,
});

export default ContactIcon;
