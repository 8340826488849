// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import useMediaQuery from '@material-ui/core/useMediaQuery';

// --- Style
import './MediaContainerStyle.sass';


const MediaContainer = ({ neverWide, children }) => {
	const wide = useMediaQuery('(min-width:768px)');

	if (neverWide) {
		return (
			<div className="mediaContainer">
				{children}
			</div>
		);
	}

	return (
		<div className={wide ? 'mediaContainer wide' : 'mediaContainer'}>
			{children}
		</div>
	);
};

MediaContainer.propTypes = {
	neverWide: PropTypes.bool,
	children: PropTypes.any,
};

MediaContainer.defaultProps = {
	neverWide: false,
};

export default MediaContainer;
