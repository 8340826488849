// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External components
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';


// --- Style
import './ImageView.sass';

// onClick={onClose}
const ImageView = ({ image, alt, onClose }) => (
	<div className="vrw-image-view">
		<IconButton
			onClick={onClose}
			className="close-button"
		>
			<Icon>close</Icon>
		</IconButton>
		<TransformWrapper
			options={{
				minScale: 1,
				maxScale: 3,
				limitToBounds: true,
				transformEnabled: true,
				limitToWrapper: true,
			}}
			// pan={{
			// 	lockAxisY: true,
			// }}
			// wheel={{
			// 	step: 100
			// }}
		>
			<TransformComponent>
				<img
					alt={alt}
					src={image}
					className="image-view-content"
				/>
			</TransformComponent>
		</TransformWrapper>
	</div>
);

ImageView.propTypes = {
	image: PropTypes.any,
	alt: PropTypes.string,
	onClose: PropTypes.func.isRequired,
};

export default ImageView;
