// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';


const get = (url, contextConfiguration) => axios.get(
	url,
	{
		...commonConfiguration,
		...contextConfiguration,
		baseURL: undefined,
	}
).catch(onRequestError);

export default get;
