// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API';
import { onRequestError } from 'io/API/errorHandling';
import { isStringNullOrEmpty } from 'logic/stringOperations';


const getUserFromCredentials = (username, password, fields = '*', contextConfiguration) => {
	const escapedUsername = encodeURI(username);
	const escapedPassword = encodeURI(password);

	let url;
	if (isStringNullOrEmpty(fields))
		url = `/users?users=${escapedUsername}&password=${escapedPassword}`;
	else
		url = `/users?users=${escapedUsername}&password=${escapedPassword}&fields=${fields}`;

	return axios.get(
		url,
		{
			...commonConfiguration,
			...contextConfiguration,
		}
	).catch(onRequestError);
};

export default getUserFromCredentials;
